export const styles = {
  globalDatasetsContainer: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    width: "1335px",
    padding: "0px 40px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "5px",
  },
  title: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "36px",
    lineHeight: "46px",
    display: "flex",
    alignItems: "center",
    color: "#01637A",
  },
  subtitle: {
    fontFamily: "Barlow",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    color: "#7C7C80",
  },
  createButton: {
    color: "#00A0B0",
    textTransform: "none",
    fontWeight: 500,
    borderRadius: 0,
    boxShadow: "none",
    backgroundColor: "transparent",
    background: "linear-gradient(270deg, #015366 0%, #01A5CC 100%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
  },
  plusIcon: {
    fontSize: "24px",
    color: "#00A0B0",
    borderImageSource: "linear-gradient(270deg, #015366 0%, #01A5CC 100%)",
  },
};
