import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./style.css";
import { Box, CircularProgress, Grid, Tooltip } from "@material-ui/core";
import LeaderBoardRow from "./LeaderBoardRow";
import useStickyHeader from "utils/useStickyHeader";
import { collaborationsService } from "services/api/collaborations";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: false,
    label: "#",
    description: "",
    leftAlign: true,
  },
  {
    id: "team",
    numeric: false,
    disablePadding: false,
    label: "Team",
    description: "",
    leftAlign: true,
  },
  {
    id: "members",
    numeric: false,
    disablePadding: false,
    label: "Members",
    description: "",
    leftAlign: true,
  },
  {
    id: "running_score",
    numeric: true,
    disablePadding: false,
    label: "Score",
    description: "",
  },
  {
    id: "final_score",
    numeric: true,
    disablePadding: false,
    label: "Final Score",
    description: "",
  },
  {
    id: "model_parameters",
    numeric: false,
    disablePadding: false,
    label: "Size (Params / Bytes)",
    description: "Size of the model in parameters and in bytes",
    leftAlign: true,
  },
  {
    id: "inference_flopsutilised",
    numeric: false,
    disablePadding: false,
    label: "Energy Consumption (FLOP / Rel %)",
    description:
      "Average energy consumption per inference in FLOP and relative energy consumption compared to the baseline (leading model)",
  },
  {
    id: "inference_gco2",
    numeric: false,
    disablePadding: false,
    label: "CO2e",
    description: "Energy consumption in grams or milligram of CO2 equivalent",
  },
  {
    id: "entries",
    numeric: false,
    disablePadding: false,
    label: "Submits",
    description: "# of Experiments run by Competitor",
  },
  {
    id: "flops",
    numeric: true,
    disablePadding: false,
    label: "Computation Budget",
    description: "Provided Computation by Host per Collaboration​",
  },
  {
    id: "last",
    numeric: true,
    disablePadding: false,
    label: "Last",
    description: "Last submitted Experiment​",
  },
];

function EnhancedTableHead(props) {
  const { martic, final_score, isSustainable } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.id === "final_score" && !final_score) {
            return null;
          }
          if (headCell.id === "inference_gco2" && !isSustainable) {
            return null;
          }
          return (
            <TableCell
              key={headCell.id}
              padding={headCell.disablePadding ? "none" : "default"}
              className={headCell.id}
              style={{}}>
              {headCell.id === "code" ? (
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>
                        Benchmarked Model results <br />
                        <br />
                      </div>
                      <div>
                        At the end of the competition
                        <br />
                        the Model which make it into
                        <br />
                        the money will be shared
                        <br />
                        with the Host.
                      </div>
                    </React.Fragment>
                  }>
                  <span>{headCell.label}</span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={`${
                    headCell.id === "score" ? martic : headCell.description
                  }`}>
                  <span>{headCell.label}</span>
                </Tooltip>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

const LeaderBoard = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [leaderboard, setLeaderBoard] = useState([]);
  const [next, setNext] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { componentRef, isBottom, coords } = useStickyHeader();

  const getLeaderBoardEntries = async (page) => {
    setLoading(true);
    try {
      const response = await collaborationsService.getLeaderboardEntries(
        props.id,
        page
      );
      const data = response.data;
      setLeaderBoard([...leaderboard, ...data.results]);
      setNext(data.next);
      setLoading(false);
      setError(null);
    } catch (error) {
      setError(
        error.response
          ? "Please make sure your credentials are correct."
          : "Make sure you are connected to the internet."
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (leaderboard.length === 0) {
      getLeaderBoardEntries();
    }
    if (leaderboard.length > 0 && next) {
      getLeaderBoardEntries(page);
    }
  }, [page]);

  useEffect(() => {
    const allZero = Object.values(coords).every((value) => value === 0);
    if ((isBottom || allZero) && next) {
      const urlSearchParams = new URLSearchParams("?" + next.split("?")[1]);
      const params = Object.fromEntries(urlSearchParams.entries());
      setPage(params.page);
    }
  }, [isBottom, next]);

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, leaderboard.length - page * rowsPerPage);

  const getFinalScoreStatus = () => {
    if (leaderboard.length > 0) {
      if (leaderboard[0]?.final_score) {
        return true;
      }
    }
    return false;
  };

  return leaderboard.length > 0 ? (
    <Box pt={1}>
      <div class="competition-leaderboard__legends">
        <div class="competition-leaderboard__legend competition-leaderboard__legend--in-the-money">
          Price contenders
        </div>
        <div class="competition-leaderboard__legend description">
          This leaderboard is calculated with approximately{" "}
          {props.test_data_percentage}% of the test data. The final results will
          be based on the other {props.final_data_percentage}%, so the final
          standings may be different.
        </div>
      </div>

      <TableContainer
        TableContainer
        ref={componentRef}
        style={{ marginTop: "8px", maxHeight: "calc(100vh - 262px)" }}
        className="table-conatainer">
        <Table
          stickyHeader
          className="leaderboard-table"
          aria-label="sticky simple table">
          <EnhancedTableHead
            martic={props.score_formula_display}
            final_score={getFinalScoreStatus()}
            classes={classes}
            numSelected={selected.length}
            rowCount={leaderboard.length}
            isSustainable={props.is_sustainable}
          />
          <TableBody>
            {leaderboard.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <LeaderBoardRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  totalEntries={leaderboard.length}
                  position={index + 1}
                  baseEnergyConsumption={leaderboard[0].inference_flopsutilised}
                  totalPrizes={props.prizes?.length}
                  selected={isItemSelected}
                  is_private={props.is_private}
                  key={row.id}
                  labelId={labelId}
                  dataset={props.dataset}
                  row={row}
                  scoreFormula={props.score_formula_display}
                  hostCompany={props?.host_company}
                  isSustainable={props?.is_sustainable}
                />
              );
            })}

            {loading ? (
              <TableRow>
                <TableCell className="empty-td" colSpan={7}>
                  <Grid container justify="center" alignItems="center">
                    <CircularProgress />
                  </Grid>
                </TableCell>
              </TableRow>
            ) : null}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 }}>
                <TableCell className="empty-td" colSpan={7}>
                  &nbsp;
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  ) : (
    <Grid
      style={{ minHeight: "200px" }}
      container
      justify="center"
      alignItems="center">
      {loading ? <CircularProgress /> : <div>No entries found</div>}
    </Grid>
  );
};

export default LeaderBoard;
