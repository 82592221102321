import React, { useState, useEffect, useRef } from "react";
import { TextField, ClickAwayListener } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    width: '100%',
  },
  text: {
    padding: '4px 4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      borderRadius: '4px',
    },
  },
  input: {
    margin: '-2px -4px',
    width: 'calc(100% + 8px)',
  },
}));

const InlineEdit = ({ value, onSave, Typography, typographyProps = {} }) => {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(value);
  const inputRef = useRef(null);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleClickAway = () => {
    if (editValue !== value) {
      onSave(editValue);
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (editValue !== value) {
        onSave(editValue);
      }
      setIsEditing(false);
    }
    if (e.key === 'Escape') {
      setEditValue(value);
      setIsEditing(false);
    }
  };

  return (
    <div className={classes.root}>
      {isEditing ? (
        <ClickAwayListener onClickAway={handleClickAway}>
          <TextField
            inputRef={inputRef}
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            onKeyDown={handleKeyDown}
            variant="outlined"
            size="small"
            className={classes.input}
          />
        </ClickAwayListener>
      ) : (
        <Typography
          {...typographyProps}
          className={`${classes.text} ${typographyProps.className || ''}`}
          onClick={handleClick}
        >
          {value}
        </Typography>
      )}
    </div>
  );
};

export default InlineEdit; 