export const Defects = [
  {
    label: "porosity",
    diameter: 2.0,
    checked: true,
  },
  {
    label: "crack",
    diameter: 1.0,
    checked: false,
  },
  {
    label: "inclusion",
    diameter: 3.5,
    checked: false,
  },
];

export const Diseases = [
  {
    label: "None",
    checked: false,
  },
  {
    label: "Atelectasis",
    checked: false,
  },
  {
    label: "Cardiomegaly",
    checked: false,
  },
  {
    label: "Hernia",
    checked: false,
  },
  {
    label: "Nodule",
    checked: false,
  },
  {
    label: "Effusion",
    checked: false,
  },
  {
    label: "Infiltration",
    checked: false,
  },
  {
    label: "Emphysema",
    checked: false,
  },
  {
    label: "Pleural_Thickening",
    checked: false,
  },
  {
    label: "Pneumothorax",
    checked: false,
  },
  {
    label: "Mass",
    checked: false,
  },
  {
    label: "Fibrosis",
    checked: false,
  },
  {
    label: "Consolidation",
    checked: false,
  },
  {
    label: "Edema",
    checked: false,
  },
  {
    label: "Pneumonia",
    checked: false,
  },
];

export const Autonomous = [
  {
    label: "car",
    checked: false,
  },
  {
    label: "person",
    checked: false,
  },
  {
    label: "traffic light",
    checked: false,
  },
  {
    label: "traffic sign",
    checked: false,
  },
  {
    label: "truck",
    checked: false,
  },
];

export const Age = [
  {
    label: "Between",
    value: 0,
    checked: true,
  },
  {
    label: "and",
    value: 99,
    checked: true,
  },
];

export const defectStandards = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "low",
    value: "low",
  },
  {
    label: "medium",
    value: "medium",
  },
  {
    label: "high",
    value: "high",
  },
];

export const tubeVoltage = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "140kV",
    value: "140kV",
  },
  {
    label: "225kV",
    value: "225kV",
  },
  {
    label: "750kV",
    value: "750kV",
  },
];

export const machineParts = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "cylinderHead",
    value: "Cylinder Head",
  },
  {
    label: "wheel carrier",
    value: "wheel carrier",
  },
];

export const material = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "aluminum",
    value: "aluminum",
  },
  {
    label: "steel",
    value: "steel",
  },
];

export const company = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "GMAC",
    value: "GMAC",
  },
  {
    label: "ElringKlinger",
    value: "ElringKlinger",
  },
];

export const gender = [
  {
    label: "all",
    value: "all",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Male",
    value: "Male",
  },
];

export const defectsColors = {
  crack: {
    light: "rgba(72, 3, 85, 1)",
    dark: "rgba(65, 14, 82, 1)",
  },
  inclusion: {
    light: "rgba(255, 167, 0, 1)",
    dark: "rgba(255, 156, 41, 1)",
  },
  porosity: {
    light: "rgba(93, 211, 158, 1)",
    dark: "rgba(125, 208, 162, 1)",
  },
};

export const deepCopy = (array) => {
  const newArray = [];
  array.forEach((v, i) => {
    const val = typeof v === "object" ? Object.assign({}, v) : v;
    newArray.push(val);
  });
  return newArray;
};

export function parseJsonString(str) {
  if (str === null || str === undefined || str === "") {
    return null;
  }
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}

export const countClasses = (classArray) => {
  let classes = 0;
  classArray &&
    Object.keys(classArray).forEach((item) => {
      if (classArray[item] > 0) {
        classes += 1;
      }
    });
  return classes;
};

export const checkCompetitors = (competitor_companies) => {
  if (!competitor_companies) {
    return false;
  }
  if (competitor_companies.length === 0) {
    return true;
  }
  return competitor_companies.every(
    (element) => element.company_type === competitor_companies[0].company_type
  );
};

export function LightenDarkenColor(color, percent) {
  // Add validation for hex color format
  const isValidHex = /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/.test(color);
  if (!isValidHex) {
    return color;
  }

  // Convert 3-digit hex to 6-digit
  if (color.length === 4) {
    color =
      "#" + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
  }

  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

export function pickHex(color1, color2, weight) {
  var w1 = weight;
  var w2 = 1 - w1;
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
  return rgb;
}

export function colorGradient(fadeFraction, rgbColor1, rgbColor2, rgbColor3) {
  var color1 = rgbColor1;
  var color2 = rgbColor2;
  var fade = fadeFraction;

  // Do we have 3 colors for the gradient? Need to adjust the params.
  if (rgbColor3) {
    fade = fade * 2;

    // Find which interval to use and adjust the fade percentage
    if (fade >= 1) {
      fade -= 1;
      color1 = rgbColor2;
      color2 = rgbColor3;
    }
  }

  var diffRed = color2.red - color1.red;
  var diffGreen = color2.green - color1.green;
  var diffBlue = color2.blue - color1.blue;

  var gradient = {
    red: parseInt(Math.floor(color1.red + diffRed * fade), 10),
    green: parseInt(Math.floor(color1.green + diffGreen * fade), 10),
    blue: parseInt(Math.floor(color1.blue + diffBlue * fade), 10),
  };

  return (
    "rgb(" + gradient.red + "," + gradient.green + "," + gradient.blue + ")"
  );
}

export function isEmail(email) {
  const pattern = new RegExp(
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  );

  return pattern.test(email);
}

export const trainingState = {
  stopped: 0,
  inQueue: 1,
  paused: 2,
  completed: 3,
  training: 4,
  aborted: 6,
};

export function encodeQuery(data) {
  const query = Object.entries(data)
    .reduce((query, [key, value]) => {
      if (Array.isArray(value)) {
        return (
          query +
          value
            .map(
              (item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`
            )
            .join("&") +
          "&"
        );
      }
      return query + `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
    }, "")
    .replace(/&$/, "");

  return query ? `?${query}` : ""; // Add "?" prefix if query exists
}

export const colorsPalate = [
  "#4BBDCC",
  "#4AB9CA",
  "#49B6C8",
  "#48B2C6",
  "#46AEC4",
  "#45AAC2",
  "#44A7C0",
  "#43A3BE",
  "#429FBC",
  "#419BBA",
  "#3F98B9",
  "#3E94B7",
  "#3D90B5",
  "#3C8CB3",
  "#3B89B1",
  "#3A85AF",
  "#3881AD",
  "#377DAB",
  "#367AA9",
  "#3576A7",
];

export function isDateBetween(from, to, check) {
  let fDate, lDate, cDate;
  fDate = Date.parse(from);
  lDate = Date.parse(to);
  cDate = Date.parse(check);

  if (cDate <= lDate && cDate >= fDate) {
    return true;
  }
  return false;
}

export const getStatusColor = (status) => {
  const statusColors = {
    1: "#39CD97",  // ONLINE
    2: "#FFA700",  // PENDING
    0: "#FF4444",  // OFFLINE
    "-1": "#FF4444" // FAILED - using same color as offline for error states
  };
  
  return statusColors[status.toString()] || "#707070";
};

export const getStatusString = (status) => {
  const statusMap = {
    1: "Online",
    2: "Pending",
    0: "Offline",
    "-1": "Failed"
  };
  
  return statusMap[status.toString()] || "Unknown";
};

export const locationChoices = [
  { value: 'AD', label: 'Andorra' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AL', label: 'Albania' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AO', label: 'Angola' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AT', label: 'Austria' },
  // Australia
  { value: 'AUS-NSW', label: 'Australia New South Wales' },
  { value: 'AUS-NT', label: 'Australia Northern Territory' },
  { value: 'AUS-QLD', label: 'Australia Queensland' },
  { value: 'AUS-SA', label: 'Australia South Australia' },
  { value: 'AUS-TAS', label: 'Australia Tasmania' },
  { value: 'AUS-TAS-CBI', label: 'Australia Cape Barren Island' },
  { value: 'AUS-TAS-FI', label: 'Australia Flinders Island' },
  { value: 'AUS-TAS-KI', label: 'Australia King Island' },
  { value: 'AUS-VIC', label: 'Australia Victoria' },
  { value: 'AUS-WA', label: 'Australia Western Australia' },
  { value: 'AUS-WA-RI', label: 'Australia Rottnest Island' },
  // B countries
  { value: 'AW', label: 'Aruba' },
  { value: 'AX', label: 'Åland Islands' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BI', label: 'Burundi' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BN', label: 'Brunei' },
  { value: 'BO', label: 'Bolivia' },
  // Brazil
  { value: 'BR-CS', label: 'Brazil Central Brazil' },
  { value: 'BR-I', label: 'Brazil Isolated Systems' },
  { value: 'BR-N', label: 'Brazil North Brazil' },
  { value: 'BR-NE', label: 'Brazil North-East Brazil' },
  { value: 'BR-S', label: 'Brazil South Brazil' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BZ', label: 'Belize' },
  
  // Canada
  { value: 'CA-AB', label: 'Canada Alberta' },
  { value: 'CA-BC', label: 'Canada British Columbia' },
  { value: 'CA-MB', label: 'Canada Manitoba' },
  { value: 'CA-NB', label: 'Canada New Brunswick' },
  { value: 'CA-NL-LB', label: 'Canada Labrador' },
  { value: 'CA-NL-NF', label: 'Canada Newfoundland' },
  { value: 'CA-NS', label: 'Canada Nova Scotia' },
  { value: 'CA-NT', label: 'Canada Northwest Territories' },
  { value: 'CA-NU', label: 'Canada Nunavut' },
  { value: 'CA-ON', label: 'Canada Ontario' },
  { value: 'CA-PE', label: 'Canada Prince Edward Island' },
  { value: 'CA-QC', label: 'Canada Québec' },
  { value: 'CA-SK', label: 'Canada Saskatchewan' },
  { value: 'CA-YT', label: 'Canada Yukon' },
  
  // C countries
  { value: 'CD', label: 'Democratic Republic of the Congo' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'CG', label: 'Congo' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'CI', label: 'Ivory Coast' },
  { value: 'CL-CHP', label: 'Chile Easter Island' },
  { value: 'CL-SEA', label: 'Chile Sistema Eléctrico de Aysén' },
  { value: 'CL-SEM', label: 'Chile Sistema Eléctrico de Magallanes' },
  { value: 'CL-SEN', label: 'Chile Sistema Eléctrico Nacional' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CN', label: 'China' },
  { value: 'CO', label: 'Colombia' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CV', label: 'Cabo Verde' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czechia' },
  // D countries
  { value: 'DE', label: 'Germany' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DK-BHM', label: 'Denmark Bornholm' },
  { value: 'DK-DK1', label: 'Denmark West Denmark' },
  { value: 'DK-DK2', label: 'Denmark East Denmark' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'DZ', label: 'Algeria' },
  
  // E countries
  { value: 'EC', label: 'Ecuador' },
  { value: 'EE', label: 'Estonia' },
  { value: 'EG', label: 'Egypt' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'ES', label: 'Spain' },
  
  // Spain regions
  { value: 'ES-CE', label: 'Spain Ceuta' },
  { value: 'ES-CN-FVLZ', label: 'Spain Fuerteventura/Lanzarote' },
  { value: 'ES-CN-GC', label: 'Spain Gran Canaria' },
  { value: 'ES-CN-HI', label: 'Spain El Hierro' },
  { value: 'ES-CN-IG', label: 'Spain Isla de la Gomera' },
  { value: 'ES-CN-LP', label: 'Spain La Palma' },
  { value: 'ES-CN-TE', label: 'Spain Tenerife' },
  { value: 'ES-IB-FO', label: 'Spain Formentera' },
  { value: 'ES-IB-IZ', label: 'Spain Ibiza' },
  { value: 'ES-IB-MA', label: 'Spain Mallorca' },
  { value: 'ES-IB-ME', label: 'Spain Menorca' },
  { value: 'ES-ML', label: 'Spain Melilla' },
  { value: 'ET', label: 'Ethiopia' },
  // F countries
  { value: 'FI', label: 'Finland' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FK', label: 'Falkland Islands' },
  { value: 'FM', label: 'Micronesia' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FO-MI', label: 'Faroe Islands Main Islands' },
  { value: 'FO-SI', label: 'Faroe Islands South Island' },
  { value: 'FR', label: 'France' },
  { value: 'FR-COR', label: 'France Corsica' },
  
  // G countries
  { value: 'GA', label: 'Gabon' },
  { value: 'GB', label: 'Great Britain' },
  { value: 'GB-NIR', label: 'Northern Ireland' },
  { value: 'GB-ORK', label: 'Great Britain Orkney Islands' },
  { value: 'GB-SHI', label: 'Great Britain Shetland Islands' },
  { value: 'GE', label: 'Georgia' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'GR', label: 'Greece' },
  { value: 'GR-IS', label: 'Greece Aegean Islands' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GU', label: 'Guam' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  // H countries
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HM', label: 'Heard Island and McDonald Islands' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HR', label: 'Croatia' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HU', label: 'Hungary' },
  
  // I countries
  { value: 'ID', label: 'Indonesia' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IL', label: 'Israel' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IN', label: 'Unknown' },
  
  // India regions
  { value: 'IN-AN', label: 'India Andaman and Nicobar Islands' },
  { value: 'IN-AP', label: 'India Andhra Pradesh' },
  { value: 'IN-AR', label: 'India Arunachal Pradesh' },
  { value: 'IN-AS', label: 'India Assam' },
  { value: 'IN-BR', label: 'India Bihar' },
  { value: 'IN-CT', label: 'India Chhattisgarh' },
  { value: 'IN-DL', label: 'India Delhi' },
  { value: 'IN-DN', label: 'India Dadra and Nagar Haveli' },
  { value: 'IN-GA', label: 'India Goa' },
  { value: 'IN-GJ', label: 'India Gujarat' },
  { value: 'IN-HP', label: 'India Himachal Pradesh' },
  { value: 'IN-HR', label: 'India Haryana' },
  { value: 'IN-JH', label: 'India Jharkhand' },
  { value: 'IN-JK', label: 'India Jammu and Kashmir' },
  { value: 'IN-KA', label: 'India Karnataka' },
  { value: 'IN-KL', label: 'India Kerala' },
  { value: 'IN-MH', label: 'India Maharashtra' },
  { value: 'IN-ML', label: 'India Meghalaya' },
  { value: 'IN-MN', label: 'India Manipur' },
  { value: 'IN-MP', label: 'India Madhya Pradesh' },
  { value: 'IN-MZ', label: 'India Mizoram' },
  { value: 'IN-NL', label: 'India Nagaland' },
  { value: 'IN-OR', label: 'India Orissa' },
  { value: 'IN-PB', label: 'India Punjab' },
  { value: 'IN-PY', label: 'India Pondicherry' },
  { value: 'IN-RJ', label: 'India Rajasthan' },
  { value: 'IN-SK', label: 'India Sikkim' },
  { value: 'IN-TN', label: 'India Tamil Nadu' },
  { value: 'IN-TR', label: 'India Tripura' },
  { value: 'IN-UP', label: 'India Uttar Pradesh' },
  { value: 'IN-UT', label: 'India Uttarakhand' },
  { value: 'IN-WB', label: 'India West Bengal' },
  
  // Iraq and other I countries
  { value: 'IQ', label: 'Iraq' },
  { value: 'IQ-KUR', label: 'Iraq Kurdistan' },
  { value: 'IR', label: 'Iran' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IT', label: 'Italy' },
  
  // Italy regions
  { value: 'IT-CNO', label: 'Italy Central North' },
  { value: 'IT-CSO', label: 'Italy Central South' },
  { value: 'IT-NO', label: 'Italy North' },
  { value: 'IT-SAR', label: 'Italy Sardinia' },
  { value: 'IT-SIC', label: 'Italy Sicily' },
  { value: 'IT-SO', label: 'Italy South' },
  
  // J countries and regions
  { value: 'JM', label: 'Jamaica' },
  { value: 'JO', label: 'Jordan' },
  { value: 'JP-CB', label: 'Japan Chūbu' },
  { value: 'JP-CG', label: 'Japan Chūgoku' },
  { value: 'JP-HKD', label: 'Japan Hokkaidō' },
  { value: 'JP-HR', label: 'Japan Hokuriku' },
  { value: 'JP-KN', label: 'Japan Kansai' },
  { value: 'JP-KY', label: 'Japan Kyūshū' },
  { value: 'JP-ON', label: 'Japan Okinawa' },
  { value: 'JP-SK', label: 'Japan Shikoku' },
  { value: 'JP-TH', label: 'Japan Tōhoku' },
  { value: 'JP-TK', label: 'Japan Tōkyō' },
  
  // K countries
  { value: 'KE', label: 'Kenya' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'KP', label: 'North Korea' },
  { value: 'KR', label: 'South Korea' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KZ', label: 'Kazakhstan' },
  
  // L countries
  { value: 'LA', label: 'Laos' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LY', label: 'Libya' },
  // M countries
  { value: 'MA', label: 'Morocco' },
  { value: 'MD', label: 'Moldova' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MK', label: 'North Macedonia' },
  { value: 'ML', label: 'Mali' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MT', label: 'Malta' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MX', label: 'Mexico' },
  
  // Mexico regions
  { value: 'MX-BC', label: 'Mexico Baja California' },
  { value: 'MX-CE', label: 'Mexico Central' },
  { value: 'MX-NE', label: 'Mexico North East' },
  { value: 'MX-NO', label: 'Mexico North' },
  { value: 'MX-NW', label: 'Mexico North West' },
  { value: 'MX-OC', label: 'Mexico Occidental' },
  { value: 'MX-OR', label: 'Mexico Oriental' },
  { value: 'MX-PN', label: 'Mexico Peninsula' },
  
  // Malaysia regions
  { value: 'MY-EM', label: 'Malaysia Borneo' },
  { value: 'MY-WM', label: 'Malaysia Peninsula' },
  
  // M-N countries
  { value: 'MZ', label: 'Mozambique' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NKR', label: 'Nagorno-Karabakh' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NO', label: 'Norway' },
  
  // Norway regions
  { value: 'NO-NO1', label: 'Norway Southeast Norway' },
  { value: 'NO-NO2', label: 'Norway Southwest Norway' },
  { value: 'NO-NO3', label: 'Norway Middle Norway' },
  { value: 'NO-NO4', label: 'Norway North Norway' },
  { value: 'NO-NO5', label: 'Norway West Norway' },
  
  // N countries continued
  { value: 'NP', label: 'Nepal' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NZ-NZA', label: 'New Zealand Auckland Islands' },
  { value: 'NZ-NZC', label: 'New Zealand Chatham Islands' },
  { value: 'NZ-NZST', label: 'New Zealand Stewart Island' },
  
  // O-P countries
  { value: 'OM', label: 'Oman' },
  { value: 'PA', label: 'Panama' },
  { value: 'PE', label: 'Peru' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PL', label: 'Poland' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'PS', label: 'State of Palestine' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PT-AC', label: 'Portugal Azores' },
  { value: 'PT-MA', label: 'Portugal Madeira' },
  { value: 'PW', label: 'Palau' },
  { value: 'PY', label: 'Paraguay' },
  
  // Q-R countries
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RS', label: 'Serbia' },
  { value: 'RU', label: 'Russia' },
  
  // Russia regions
  { value: 'RU-1', label: 'Russia Europe-Ural' },
  { value: 'RU-2', label: 'Russia Siberia' },
  { value: 'RU-AS', label: 'Russia East' },
  { value: 'RU-EU', label: 'Russia Arctic' },
  { value: 'RU-FE', label: 'Russia Far East' },
  { value: 'RU-KGD', label: 'Russia Kaliningrad' },
  { value: 'RW', label: 'Rwanda' },
  
  // S countries
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SE', label: 'Sweden' },
  
  // Sweden regions
  { value: 'SE-SE1', label: 'Sweden North Sweden' },
  { value: 'SE-SE2', label: 'Sweden North Central Sweden' },
  { value: 'SE-SE3', label: 'Sweden South Central Sweden' },
  { value: 'SE-SE4', label: 'Sweden South Sweden' },
  
  // More S countries
  { value: 'SG', label: 'Singapore' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SN', label: 'Senegal' },
  { value: 'SO', label: 'Somalia' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'SY', label: 'Syria' },
  { value: 'SZ', label: 'Swaziland' },
  // T countries
  { value: 'TD', label: 'Chad' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'TG', label: 'Togo' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TZ', label: 'Tanzania' },
  
  // Ukraine
  { value: 'UA', label: 'Ukraine' },
  { value: 'UA-CR', label: 'Ukraine Crimea' },
  { value: 'UG', label: 'Uganda' },
  { value: 'US-AK', label: 'Unknown' },
  
  // USA regions - California
  { value: 'US-CAL-BANC', label: 'USA Balancing Authority Of Northern California' },
  { value: 'US-CAL-CISO', label: 'USA California Independent System Operator' },
  { value: 'US-CAL-IID', label: 'USA Imperial Irrigation District' },
  { value: 'US-CAL-LDWP', label: 'USA Los Angeles Department Of Water And Power' },
  { value: 'US-CAL-TIDC', label: 'USA Turlock Irrigation District' },
  
  // USA regions - Carolinas
  { value: 'US-CAR-CPLE', label: 'USA Duke Energy Progress East' },
  { value: 'US-CAR-CPLW', label: 'USA Duke Energy Progress West' },
  { value: 'US-CAR-DUK', label: 'USA Duke Energy Carolinas' },
  { value: 'US-CAR-SC', label: 'USA South Carolina Public Service Authority' },
  { value: 'US-CAR-SCEG', label: 'USA South Carolina Electric & Gas Company' },
  { value: 'US-CAR-YAD', label: 'USA Alcoa Power Generating, Inc. Yadkin Division' },
  
  // USA regions - Central
  { value: 'US-CENT-SPA', label: 'USA Southwestern Power Administration' },
  { value: 'US-CENT-SWPP', label: 'USA Southwest Power Pool' },
  
  // USA regions - Florida
  { value: 'US-FLA-FMPP', label: 'USA Florida Municipal Power Pool' },
  { value: 'US-FLA-FPC', label: 'USA Duke Energy Florida Inc' },
  { value: 'US-FLA-FPL', label: 'USA Florida Power & Light Company' },
  { value: 'US-FLA-GVL', label: 'USA Gainesville Regional Utilities' },
  { value: 'US-FLA-HST', label: 'USA City Of Homestead' },
  { value: 'US-FLA-JEA', label: 'USA Jacksonville Electric Authority' },
  { value: 'US-FLA-NSB', label: 'USA Utilities Commission Of New Smyrna Beach' },
  { value: 'US-FLA-SEC', label: 'USA Seminole Electric Cooperative' },
  { value: 'US-FLA-TAL', label: 'USA City Of Tallahassee' },
  { value: 'US-FLA-TEC', label: 'USA Tampa Electric Company' },
  
  // USA regions - Hawaii
  { value: 'US-HI-HA', label: 'USA Hawaii' },
  { value: 'US-HI-KA', label: 'USA Kauai' },
  { value: 'US-HI-KH', label: 'USA Kahoolawe' },
  { value: 'US-HI-LA', label: 'USA Lanai' },
  { value: 'US-HI-MA', label: 'USA Maui' },
  { value: 'US-HI-MO', label: 'USA Molokai' },
  { value: 'US-HI-NI', label: 'USA Niihau' },
  { value: 'US-HI-OA', label: 'USA Oahu' },
  
  // USA regions - Mid-Atlantic and Midwest
  { value: 'US-MIDA-PJM', label: 'USA PJM Interconnection, Llc' },
  { value: 'US-MIDW-AECI', label: 'USA Associated Electric Cooperative, Inc.' },
  { value: 'US-MIDW-GLHB', label: 'USA GridLiance' },
  { value: 'US-MIDW-LGEE', label: 'USA Louisville Gas And Electric Company And Kentucky Utilities' },
  { value: 'US-MIDW-MISO', label: 'USA Midcontinent Independent Transmission System Operator, Inc.' },
  { value: 'US-NE-ISNE', label: 'USA Iso New England Inc.' },
  
  // USA regions - Northwest
  { value: 'US-NW-AVA', label: 'USA Avista Corporation' },
  { value: 'US-NW-AVRN', label: 'USA Avangrid Renewables Cooperative' },
  { value: 'US-NW-BPAT', label: 'USA Bonneville Power Administration' },
  { value: 'US-NW-CHPD', label: 'USA PUD No. 1 Of Chelan County' },
  { value: 'US-NW-DOPD', label: 'USA PUD No. 1 Of Douglas County' },
  { value: 'US-NW-GCPD', label: 'USA PUD No. 2 Of Grant County, Washington' },
  { value: 'US-NW-GRID', label: 'USA Gridforce Energy Management, Llc' },
  { value: 'US-NW-GWA', label: 'USA Naturener Power Watch, Llc (Gwa)' },
  { value: 'US-NW-IPCO', label: 'USA Idaho Power Company' },
  { value: 'US-NW-NEVP', label: 'USA Nevada Power Company' },
  { value: 'US-NW-NWMT', label: 'USA Northwestern Energy' },
  { value: 'US-NW-PACE', label: 'USA Pacificorp East' },
  { value: 'US-NW-PACW', label: 'USA Pacificorp West' },
  { value: 'US-NW-PGE', label: 'USA Portland General Electric Company' },
  { value: 'US-NW-PSCO', label: 'USA Public Service Company Of Colorado' },
  { value: 'US-NW-PSEI', label: 'USA Puget Sound Energy' },
  { value: 'US-NW-SCL', label: 'USA Seattle City Light' },
  { value: 'US-NW-TPWR', label: 'USA City Of Tacoma, Department Of Public Utilities, Light Division' },
  { value: 'US-NW-WACM', label: 'USA Western Area Power Administration - Rocky Mountain Region' },
  { value: 'US-NW-WAUW', label: 'USA Western Area Power Administration UGP West' },
  { value: 'US-NW-WWA', label: 'USA Naturener Wind Watch, Llc' },
  { value: 'US-NY-NYIS', label: 'USA New York Independent System Operator' },
  
  // USA regions - Southeast and Southwest
  { value: 'US-SE-AEC', label: 'USA Powersouth Energy Cooperative' },
  { value: 'US-SE-SEPA', label: 'USA Southeastern Power Administration' },
  { value: 'US-SE-SOCO', label: 'USA Southern Company Services, Inc. - Trans' },
  { value: 'US-SW-AZPS', label: 'USA Arizona Public Service Company' },
  { value: 'US-SW-DEAA', label: 'USA Arlington Valley, LLC' },
  { value: 'US-SW-EPE', label: 'USA El Paso Electric Company' },
  { value: 'US-SW-GRIF', label: 'USA Griffith Energy, LLC' },
  { value: 'US-SW-GRMA', label: 'USA Gila River Power, LLC' },
  { value: 'US-SW-HGMA', label: 'USA New Harquahala Generating Company, LLC' },
  { value: 'US-SW-PNM', label: 'USA Public Service Company Of New Mexico' },
  { value: 'US-SW-SRP', label: 'USA Salt River Project' },
  { value: 'US-SW-TEPC', label: 'USA Tucson Electric Power Company' },
  { value: 'US-SW-WALC', label: 'USA Western Area Power Administration - Desert Southwest Region' },
  
  // USA regions - Tennessee and Texas
  { value: 'US-TEN-TVA', label: 'USA Tennessee Valley Authority' },
  { value: 'US-TEX-ERCO', label: 'USA Electric Reliability Council Of Texas, Inc.' },
  
  // V-Z countries
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'VE', label: 'Venezuela' },
  { value: 'VI', label: 'USA Virgin Islands' },
  { value: 'VN', label: 'Vietnam' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'WS', label: 'Samoa' },
  { value: 'XK', label: 'Kosovo' },
  { value: 'XX', label: 'Northern Cyprus' },
  { value: 'YE', label: 'Yemen' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
];

export const parseDatasetMetaTotal = (metaData) => {
  try {
    return metaData?.total || 0;
  } catch (error) {
    console.error('Error parsing dataset_meta:', error);
    return 0;
  }
};
