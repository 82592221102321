import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const TransferOwnershipDialog = ({ 
  open, 
  onClose, 
  onTransfer, 
  isLoading, 
  apiErrors,
  currentOwner 
}) => {
  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      return "Email is required";
    }
    if (!emailRegex.test(email)) {
      return "Invalid email format";
    }
    if (email === currentOwner?.email) {
      return "Cannot transfer to current owner";
    }
    return "";
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setValidationError(validateEmail(value));
  };

  const handleTransfer = () => {
    const error = validateEmail(email);
    if (error) {
      setValidationError(error);
      return;
    }
    onTransfer(email);
  };

  const handleClose = () => {
    setEmail("");
    setValidationError("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Box p={2}>
        <DialogTitle>Transfer Company Ownership</DialogTitle>
        <DialogContent>
          {apiErrors && (
            <Box mb={2}>
              <Alert severity="error">
                {typeof apiErrors === 'string' ? apiErrors : 'Failed to transfer ownership'}
              </Alert>
            </Box>
          )}
          
          <Box mt={2}>
            <Typography color="textSecondary" gutterBottom>
              Current Owner: {currentOwner?.first_name} {currentOwner?.last_name}
            </Typography>
            <Typography color="error" variant="body2" gutterBottom>
              Warning: This action will transfer all company ownership rights to the new owner.
            </Typography>
            <Box mt={2}>
              <TextField
                label="New Owner Email"
                fullWidth
                value={email}
                onChange={handleEmailChange}
                variant="outlined"
                error={!!validationError}
                helperText={validationError}
                placeholder="email@example.com"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box style={{ padding: "16px" }}>
            <Button onClick={handleClose} color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button
              onClick={handleTransfer}
              color="primary"
              style={{ backgroundColor: "rgba(227, 16, 16, 0.8)" }}
              variant="contained"
              disabled={isLoading || !!validationError}
            >
              {isLoading ? "Transferring..." : "Transfer Ownership"}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default TransferOwnershipDialog; 