import { Avatar, Box, Grid } from "@material-ui/core";
import React, { useContext, useState } from "react";
import "./style.css";
import Header from "../../Components/Header";
import CollaborationList from "../../Components/CollaborationList";
import CreateCollaborationDialog from "./CreateCollaborationDialog";
import { GlobalContext } from "Context/GlobalState";

const Collaboration = (props) => {
  const { user } = useContext(GlobalContext);
  const [value, setValue] = useState("active");
  const [createCollaboration, setCreateCollaboration] = useState(false);

  return (
    <>
      <Box paddingX={5} className="competitions-list">
        <Box pb={0} className="title-italic-24">
          {(user?.company && `${user?.company} - `) || ""}AI Collaboration Space
        </Box>

        <Grid
          justifyContent="space-between"
          className="competition-head"
          container>
          <Grid style={{ minWidth: "60%", maxWidth: "70%" }} item>
            <Grid alignItems="center" container>
              <Grid item className="avatar-group">
                <Avatar
                  src="https://i.pravatar.cc/40?id=1"
                  className={"avatar"}
                />
                <Avatar
                  src="https://i.pravatar.cc/40?id=2"
                  className={"avatar"}
                />
                <Avatar
                  src="https://i.pravatar.cc/40?id=3"
                  className={"avatar"}
                />
                <Avatar
                  src="https://i.pravatar.cc/40?id=4"
                  className={"avatar"}
                />
                <Avatar
                  src="https://i.pravatar.cc/40?id=5"
                  className={"avatar"}
                />
                <Avatar
                  src="https://i.pravatar.cc/40?id=6"
                  className={"avatar"}
                />
              </Grid>
              <Grid item>
                <div className="description-text">
                  Securely collaborate with leading AI experts to develop
                  high-performing AI models on your proprietary data.
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid style={{ minWidth: "30%", maxWidth: "40%" }} item>
            <Header />
          </Grid>
        </Grid>

        <CollaborationList 
          filter={value} 
          onCreateCollaboration={() => setCreateCollaboration(true)} 
        />
      </Box>
      <CreateCollaborationDialog
        open={createCollaboration}
        onClose={() => setCreateCollaboration(false)}
      />
    </>
  );
};

export default Collaboration;
