import React, { memo, useState, useRef, useEffect } from 'react';
import './styles.css';
import { getStatusString } from '../../utils';
import { nFormatter } from '../../utils/format';
import { Tooltip } from '@material-ui/core';

const MetaDataCard = memo((props) => {
  // Extract relevant data from props
  const {
    id,
    title,
    dataset_key,
    dataset_meta,
    category,
    description,
    dataset_type,
    requires_gpu,
    created_date,
    edge_devices,
    isCompetition,
    onPencilClick
  } = props;

  // Add state to control tooltip visibility
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const tooltipRef = useRef(null);
  const infoIconRef = useRef(null);

  // Handle outside clicks to close tooltip
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipOpen && 
          tooltipRef.current && 
          infoIconRef.current && 
          !tooltipRef.current.contains(event.target) &&
          !infoIconRef.current.contains(event.target)) {
        setTooltipOpen(false);
      }
    };

    if (tooltipOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltipOpen]);

  const handleCopyClick = (datasetId) => {
    // Copy metadata ID to clipboard
    navigator.clipboard.writeText(datasetId);
  };

  const handleInfoClick = () => {
    // Toggle tooltip visibility on click
    setTooltipOpen(prevState => !prevState);
    console.log('Info icon clicked', id);
  };

  // Format date to readable format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Get total number of samples
  const totalSamples = dataset_meta?.total || 0;

  // Get category display name and color
  const getCategoryDisplayName = (cat) => {
    const categories = {
      'image_classification': 'image classification',
      'object_detection': 'object detection',
      'segmentation': 'segmentation',
      'keypoint_detection': 'keypoint detection',
      'text_classification': 'text classification',
      'tabular': 'tabular',
    };
    return categories[cat] || cat;
  };

  // Get tag color based on category
  const getCategoryTagColor = (cat) => {
    const categoryColorMap = {
      'image_classification': 'tag--red',
      'object_detection': 'tag--red',
      'segmentation': 'tag--red',
      'keypoint_detection': 'tag--red',
      'text_classification': 'tag--blue',
      'tabular': 'tag--purple',
    };
    return categoryColorMap[cat] || 'tag--red';
  };

  // Get dataset type display name and color
  const getTrainOrTest = (type) => {
    const trainOrTestColorMap = {
      'train': 'tag--bluish',
      'test': 'tag--yellow',
    };
    return trainOrTestColorMap[type?.toLowerCase()] || 'tag--bluish';
  };
  // Get client tag color based on device status
  const getClientTagColor = (statusCode) => {
    const status = getStatusString(statusCode);
    const statusColorMap = {
      'active': 'client-tag--green',
      'inactive': 'client-tag--red',
      'pending': 'client-tag--yellow',
      'maintenance': 'client-tag--orange',
      'offline': 'client-tag--gray'
    };
    return statusColorMap[status?.toLowerCase()] || 'client-tag--green'; // Default to green if status is unknown
  };

  // Get primary edge device (for client display)
  const primaryEdgeDevice = edge_devices && edge_devices.length > 0 ? edge_devices[0] : null;

  return (
    <div 
      className="metadata-card"
      role="article"
      aria-label={`Metadata card for ${title}`}
    >
      <div className="header">
        <div className="tags">
          <span className={`tag ${getCategoryTagColor(category)}`}>{getCategoryDisplayName(category)}</span>
          {dataset_type && <span className={`tag ${getTrainOrTest(dataset_type)}`}>{dataset_type}</span>}
        </div>
        <div 
          className="icons"
          tabIndex="0"
          role="button"
          aria-label="More information"
        >
          <span onClick={onPencilClick} className="pencil-icon">
            <img src={'images/icons/pencil.png'} alt="Info" width={24} height={24} />
          </span>
          <Tooltip
            interactive
            disableFocusListener
            disableHoverListener
            disableTouchListener
            open={tooltipOpen}
            onClose={() => setTooltipOpen(false)}
            title={
              <div className="info-tooltip" ref={tooltipRef}>
                {/* <div className="tooltip-header">
                  <span className="tooltip-title">Dataset Info</span>
                </div> */}
                <div className="tooltip-content">
                  <div className="tooltip-row">
                    <span className="tooltip-label">Namespace:</span>
                    <span className="tooltip-value">{primaryEdgeDevice?.location || "Unknown"}</span>
                  </div>
                  <div className="tooltip-row">
                    <span className="tooltip-label">Pod:</span>
                    <span className="tooltip-value">{id || dataset_key}</span>
                  </div>
                  <div className="tooltip-row">
                    <span className="tooltip-label">Table:</span>
                    <span className="tooltip-value">{title || "documents"}</span>
                  </div>
                </div>
              </div>
            }
            arrow
            placement="right"
            classes={{
              tooltip: "custom-tooltip",
              arrow: "custom-arrow"
            }}
          >
            <span 
              onClick={handleInfoClick} 
              className="info-icon"
              ref={infoIconRef}
            >
              <img src={'images/icons/info.png'} alt="Info" width={24} height={24} />
            </span>
          </Tooltip>
        </div>
        
      </div>
      <h2 className="title">{title}</h2>
      <div className="description">
        { description 
          ? description.substring(0, 150) + (description.length > 150 ? '...' : '')
          : "No description available."}
      </div>
      <div className="details">
        <div className="detail">
          <span>Size</span>
          <span>{nFormatter(totalSamples)} rows</span>
        </div>
        <div className="detail">
          <span>Metadata ID</span>
          <span onClick={() => handleCopyClick(dataset_key)} className="metadata-id">
            <img
              src={'images/icons/copy.png'} alt="Copy" width={15} height={15} />
            <span>{dataset_key}</span>
          </span>
        </div>
        <div className="detail">
          <span>Created Date</span>
          <span>{formatDate(created_date)}</span>
        </div>
      </div>
      <hr className="hr"/>
      <div className="details">
        <div className="detail">
          <span>Namespace</span>
          <span>{primaryEdgeDevice?.location || "Unknown"}</span>
        </div>
        <div className="detail">
          <span>Client</span>
          <span className="client">
            {edge_devices.map((device, index) => (
                <span 
                  key={device.id || index}
                  className={`client-tag ${getClientTagColor(device.status)}`}
                >
                  {device.first_name || device.id}
                </span>
            ))}
          </span>
        </div>
        <div className="detail">
          <span>Requires GPU</span>
          <span>{requires_gpu ? "Yes" : "No"}</span>
        </div>
      </div>
    </div>
  );
});

export default MetaDataCard;