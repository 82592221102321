import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Grid,
  Chip,
  IconButton,
  Button,
  Snackbar,
  Box,
} from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import PhonelinkSetupOutlinedIcon from "@material-ui/icons/PhonelinkSetupOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import AddDeviceDialog from "./AddDeviceDialog";
import UpdateDeviceDialog from "./UpdateDeviceDialog";
import { edgeDevicesService } from "services/api/edgeDevices";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import UpdatePasswordDialog from "./UpdatePasswordDialog";
import { getStatusColor, getStatusString } from "utils";
const useStyles = makeStyles({
  newRow: {
    animation: "$highlightFade 5s ease-in-out",
  },
  "@keyframes highlightFade": {
    "0%": {
      backgroundColor: "rgba(58, 90, 189, 0.5)", // Light blue background
    },
    "100%": {
      backgroundColor: "transparent",
    },
  },
  loadingMore: {
    display: "flex",
    justifyContent: "center",
  },
});

const EdgeDevicesTable = ({
  devices: initialDevices,
  loading: initialLoading,
  onDeviceAdded,
  nextPage: initialNextPage,
}) => {
  const classes = useStyles();
  const [devices, setDevices] = useState(initialDevices);
  const [loading, setLoading] = useState(initialLoading);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [nextPage, setNextPage] = useState(initialNextPage);
  const loadingRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [addingDevice, setAddingDevice] = useState(false);
  const [apiErrors, setApiErrors] = useState(null);
  const [newDeviceId, setNewDeviceId] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [updatingDevice, setUpdatingDevice] = useState(false);
  const [updateApiErrors, setUpdateApiErrors] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    deviceId: null,
    deviceName: "",
  });
  const [deletingDevice, setDeletingDevice] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [selectedPasswordDevice, setSelectedPasswordDevice] = useState(null);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [passwordApiErrors, setPasswordApiErrors] = useState(null);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setApiErrors(null); // Clear any previous errors
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setApiErrors(null); // Clear errors on close
  };

  const handleCloseSnackbar = () =>
    setSnackbar((prev) => ({ ...prev, open: false }));

  const handleAddDevice = async (deviceData) => {
    const { name, ...rest } = deviceData;
    setAddingDevice(true);
    setApiErrors(null);

    try {
      const { data } = await edgeDevicesService.createEdgeDevice({ first_name: name, ...rest });
      setSnackbar({
        open: true,
        message: "Device added successfully",
        severity: "success",
      });
      setNewDeviceId(data.id); // Set the new device ID
      // Clear the highlight after animation
      setTimeout(() => setNewDeviceId(null), 5000);
      onDeviceAdded?.(data);
      handleCloseDialog();
    } catch (error) {
      console.error("Error adding device:", error);
      if (error.response?.data) {
        setApiErrors(error.response.data);
      } else {
        setSnackbar({
          open: true,
          message: "Failed to add device. Please try again.",
          severity: "error",
        });
      }
    } finally {
      setAddingDevice(false);
    }
  };

  const handleEdit = (deviceId) => {
    const device = devices.find((d) => d.id === deviceId);
    setSelectedDevice(device);
    setOpenUpdateDialog(true);
    setUpdateApiErrors(null);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
    setSelectedDevice(null);
    setUpdateApiErrors(null);
  };

  const handleUpdateDevice = async (updatedData) => {
    setUpdatingDevice(true);
    setUpdateApiErrors(null);

    try {
      const { data } = await edgeDevicesService.updateEdgeDevice(
        selectedDevice.id,
        updatedData
      );
      setSnackbar({
        open: true,
        message: "Device updated successfully",
        severity: "success",
      });

      // Update the devices list with the updated device
      setDevices((prevDevices) =>
        prevDevices.map((device) => {
          if (device.id === selectedDevice.id) {
            setNewDeviceId(device.id);
            return { ...device, ...data };
          }
          return device;
        })
      );

      handleCloseUpdateDialog();
    } catch (error) {
      console.error("Error updating device:", error);
      if (error.response?.data) {
        setUpdateApiErrors(error.response.data);
      } else {
        setSnackbar({
          open: true,
          message: "Failed to update device. Please try again.",
          severity: "error",
        });
      }
    } finally {
      setUpdatingDevice(false);
    }
  };

  const handleDelete = (deviceId) => {
    const device = devices.find((d) => d.id === deviceId);
    setDeleteDialog({
      open: true,
      deviceId: deviceId,
      deviceName: device.username,
    });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialog({
      open: false,
      deviceId: null,
      deviceName: "",
    });
  };

  const handleConfirmDelete = async () => {
    setDeletingDevice(true);
    try {
      await edgeDevicesService.deleteEdgeDevice(deleteDialog.deviceId);

      // Remove the device from the local state
      setDevices((prevDevices) =>
        prevDevices.filter((device) => device.id !== deleteDialog.deviceId)
      );

      setSnackbar({
        open: true,
        message: "Device deleted successfully",
        severity: "success",
      });

      handleCloseDeleteDialog();
    } catch (error) {
      console.error("Error deleting device:", error);
      setSnackbar({
        open: true,
        message: "Failed to delete device. Please try again.",
        severity: "error",
      });
    } finally {
      setDeletingDevice(false);
    }
  };

  const handleUpdatePassword = (deviceId) => {
    const device = devices.find((d) => d.id === deviceId);
    setSelectedPasswordDevice(device);
    setOpenPasswordDialog(true);
    setPasswordApiErrors(null);
  };

  const handleClosePasswordDialog = () => {
    setOpenPasswordDialog(false);
    setSelectedPasswordDevice(null);
    setPasswordApiErrors(null);
  };

  const handlePasswordUpdate = async (updateData) => {
    setUpdatingPassword(true);
    setPasswordApiErrors(null);

    try {
      await edgeDevicesService.updateEdgeDevice(
        selectedPasswordDevice.id,
        updateData
      );
      setSnackbar({
        open: true,
        message: "Password updated successfully",
        severity: "success",
      });
      handleClosePasswordDialog();
      setNewDeviceId(selectedPasswordDevice.id);
    } catch (error) {
      console.error("Error updating password:", error);
      if (error.response?.data) {
        setPasswordApiErrors(error.response.data);
      } else {
        setSnackbar({
          open: true,
          message: "Failed to update password. Please try again.",
          severity: "error",
        });
      }
    } finally {
      setUpdatingPassword(false);
    }
  };

  // Memoize loadMoreDevices to prevent infinite re-renders
  const loadMoreDevices = useCallback(async () => {
    if (!nextPage || loadingMore) return;

    setLoadingMore(true);
    try {
      const response = await edgeDevicesService.getUserEdgeDevices(nextPage);
      const newDevices = response.data.results;

      setDevices((prev) => [...prev, ...newDevices]);
      setNextPage(response.data.next);
      setHasMore(!!response.data.next);
    } catch (error) {
      console.error("Error loading more devices:", error);
      setSnackbar({
        open: true,
        message: "Failed to load more devices",
        severity: "error",
      });
    } finally {
      setLoadingMore(false);
    }
  }, [nextPage, loadingMore]); // Dependencies for useCallback

  // Update the intersection observer to watch loadingRef
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting && hasMore && !loading) {
          console.log("nextPage", nextPage);
          if (nextPage) {
            loadMoreDevices(nextPage);
          }
        }
      },
      { threshold: 0.1 }
    );

    const currentRef = loadingRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [hasMore, loadMoreDevices, loading, loadingMore, nextPage]); // Include loadMoreDevices in dependencies

  // Update devices when initialDevices prop changes
  useEffect(() => {
    setDevices(initialDevices);
  }, [initialDevices]);

  useEffect(() => {
    setNextPage(initialNextPage);
  }, [initialNextPage]);

  // Update loading state when initialLoading prop changes
  useEffect(() => {
    setLoading(initialLoading);
  }, [initialLoading]);

  if (loading) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ minHeight: "200px" }}>
        <CircularProgress />
      </Grid>
    );
  }

  const noDevices = () => {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ minHeight: "400px", padding: "24px" }}>
        <Box textAlign="center" maxWidth="400px">
          <PhonelinkSetupOutlinedIcon
            style={{ width: "400px", marginBottom: "24px", fontSize: "40px" }}
          />
          <h3
            style={{
              fontSize: "24px",
              fontWeight: 500,
              marginBottom: "12px",
              color: "#1F2937",
            }}>
            No Clients Found
          </h3>
          <p
            style={{
              color: "#6B7280",
              marginBottom: "24px",
            }}>
            Get started by adding your first client device
          </p>
          <div
            style={{ display: "flex", gap: "12px", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddOutlinedIcon />}
              onClick={handleOpenDialog}>
              Add Client
            </Button>
            <Button
              variant="outlined"
              color="primary"
              href="https://docs.tracebloc.io/client-setup"
              target="_blank"
              rel="noopener noreferrer">
              View Documentation
            </Button>
          </div>
        </Box>
      </Grid>
    );
  };

  return (
    <>
      {devices.length === 0 && noDevices()}
      {devices.length > 0 && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "16px",
            }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddOutlinedIcon />}
            onClick={handleOpenDialog}
            aria-label="Add new edge device">
            Add Client
          </Button>
        </div>
      
      <TableContainer className="table-container">
        <Table className="table">
          <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                <TableCell>Client ID</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>CPU Details</TableCell>
                <TableCell>GPU Details</TableCell>
                <TableCell>PUE Constant</TableCell>
                <TableCell>Date Joined</TableCell>
                <TableCell>Status</TableCell>
                <TableCell
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginRight: "16px",
                  }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {devices.map((device, index) => (
                <TableRow
                  key={device.id}
                  className={`table-row ${
                    index % 2 === 0 ? "row-even" : "row-odd"
                    } ${device.id === newDeviceId ? classes.newRow : ""}`}>
                  <TableCell style={{ width: '10%' }}>
                    <span style={{ fontWeight: 500, color: '#1F2937' }}>{device.first_name}</span>
                  </TableCell>
                  <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <span style={{ 
                        color: '#6B7280', 
                        fontSize: '0.875rem',
                        backgroundColor: '#F3F4F6',
                        padding: '2px 8px',
                        borderRadius: '4px',
                        fontFamily: 'monospace'
                      }}>
                        {device.username}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>{device.location}</TableCell>
                  <TableCell>
                    Cores:{" "}
                    <span style={{ fontStyle: "italic", color: "#888" }}>
                      {device.v_cpu_cores}
                    </span>
                    , TDP:{" "}
                    <span style={{ fontStyle: "italic", color: "#888" }}>
                      {device.tdp_of_cpu}W
                    </span>
                  </TableCell>
                  <TableCell>
                    Cores:{" "}
                    <span style={{ fontStyle: "italic", color: "#888" }}>
                      {device.v_gpu_cores}
                    </span>
                    , TDP:{" "}
                    <span style={{ fontStyle: "italic", color: "#888" }}>
                      {device.tdp_of_gpu}W
                    </span>
                  </TableCell>
                  <TableCell>{device.pue_constant}</TableCell>
                  <TableCell>
                    {new Date(device.date_joined).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={getStatusString(device?.status)}
                      style={{
                        backgroundColor: getStatusColor(device?.status),
                        color: "#FFFFFF",
                        fontSize: "10px",
                        height: "24px",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "0px",
                      }}>
                      <IconButton
                        onClick={() => handleEdit(device.id)}
                        onKeyDown={(e) =>
                          e.key === "Enter" && handleEdit(device.id)
                        }
                        className="text-indigo-600 hover:text-indigo-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 rounded-md p-1"
                        aria-label={`Edit ${device.username}`}
                        tabIndex={0}>
                        <EditOutlinedIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleUpdatePassword(device.id)}
                        onKeyDown={(e) =>
                          e.key === "Enter" && handleUpdatePassword(device.id)
                        }
                        className="text-yellow-600 hover:text-yellow-900 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 rounded-md p-1"
                        aria-label={`Update password for ${device.username}`}
                        tabIndex={0}>
                        <LockOutlinedIcon />
                      </IconButton>
                      {/* <IconButton
                      onClick={() => handleDelete(device.id)}
                      onKeyDown={(e) => e.key === 'Enter' && handleDelete(device.id)}
                      className="text-red-600 hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 rounded-md p-1"
                      aria-label={`Delete ${device.username}`}
                      tabIndex={0}
                    >
                      <DeleteOutlinedIcon />
                    </IconButton> */}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div ref={loadingRef} className={classes.loadingMore}>
          {loadingMore ? (
            <CircularProgress size={24} style={{ marginTop: "20px" }} />
          ) : hasMore ? (
            "Loading more..."
          ) : (
            ""
          )}
        </div>
      </div>
        )}
      <AddDeviceDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onAdd={handleAddDevice}
        isLoading={addingDevice}
        apiErrors={apiErrors}
      />

      <UpdateDeviceDialog
        open={openUpdateDialog}
        onClose={handleCloseUpdateDialog}
        onUpdate={handleUpdateDevice}
        device={selectedDevice}
        isLoading={updatingDevice}
        apiErrors={updateApiErrors}
      />

      <DeleteConfirmationDialog
        open={deleteDialog.open}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        deviceName={deleteDialog.deviceName}
        isLoading={deletingDevice}
      />

      <UpdatePasswordDialog
        open={openPasswordDialog}
        onClose={handleClosePasswordDialog}
        onUpdate={handlePasswordUpdate}
        device={selectedPasswordDevice}
        isLoading={updatingPassword}
        apiErrors={passwordApiErrors}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled">
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EdgeDevicesTable;
