import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';



export const CustomTab = withStyles((theme) => ({

  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    margin: '0px -1px',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: '1px solid rgba(230, 230, 230, 1)',
    minHeight: '30px',
    height: '30px',
    top: '5px',
    padding: '0px 16px',
    color: '#121314',
    fontWeight: '400',
    fontSize: '14px',
    fontStyle: 'italic',
    fontFamily: 'Roboto, Arial',
    opacity: 1,
    '&:hover': {
      color: 'rgba(53, 118, 167, 1)',
      opacity: 1,
    },
    '&$selected': {
      color: 'rgba(53, 118, 167, 1)',
      fontWeight: theme.typography.fontWeightMedium,
      border: '1px solid rgba(230, 230, 230, 1)',
      borderRadius: '5px',
      height: '34px',
      top: '2px',
      left: '1px',
      marginRight: '1px',
      fontWeight: '700',
      fontFamily: 'Roboto, Arial'
    },
    '&:focus': {
      color: 'rgba(53, 118, 167, 1)',
    },
  },
  selected: {},
}))((props) => {
  const { title, ...otherProps } = props;
  return (
    <Tooltip title={title || props.label}>
      <Tab disableRipple {...otherProps} />
    </Tooltip>
  );
});