import axios from "axios";
import { API_URL, getAuthConfig } from "./config";



export const companyService = {
  getCompanyDetails: () => axios.get(`${API_URL}/company-management/`, getAuthConfig()),
  updateCompanyDetails: (data, companyId) => {
    const config = {
      ...getAuthConfig(),
      headers: {
        ...getAuthConfig().headers,
        ...(data instanceof FormData ? {} : { 'Content-Type': 'application/json' }),
      },
    };
    return axios.patch(
      `${API_URL}/company-management/${companyId}/`,
      data instanceof FormData ? data : JSON.stringify(data),
      config
    );
  },
  addAdmins: (emails, companyId) => axios.patch(`${API_URL}/company-management/${companyId}/add_admins/`,
    { emails }, getAuthConfig()),
  addDomain: (domain) => axios.post(`${API_URL}/company-management/domain/`,
    domain, getAuthConfig()),
  removeAdmins: (adminEmails, companyId) => axios.patch(`${API_URL}/company-management/${companyId}/remove_admins/`,
    { admin_emails: adminEmails }, getAuthConfig()),
  deleteDomain: (domainId) => axios.delete(
    `${API_URL}/company-management/domain/${domainId}/`,
    getAuthConfig()
  ),
  transferOwnership: (email, companyId) => axios.patch(
    `${API_URL}/company-management/${companyId}/transfer_ownership/`,
    { email },
    getAuthConfig()
  ),
}; 