import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { CircularProgress, Grid, Box } from "@material-ui/core";

import { GlobalContext } from "../../Context/GlobalState";
import MetaDataCard from "../MetaDataCard";
import EditDialog from '../MetaDataCard/EditDialog';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const GlobalDatasetList = (props) => {
  const {
    state: {
      datasets,
      error,
      datasets: { list, loading, next },
    },
    getAdminDatasets,
    updateDatasetMetadata
  } = useContext(GlobalContext);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedDataset, setSelectedDataset] = useState(null);

  useEffect(() => {
    if (list.length === 0 && !loading) {
      getAdminDatasets();
    }
    if (list.length > 0 && datasets.next && page > 0 && !loading) {
      getAdminDatasets(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePencilClick = (dataset) => {
    setSelectedDataset(dataset);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setSelectedDataset(null);
  };

  const handleUpdate = (updatedDataset) => {
    // Refresh data logic here
    handleDialogClose();
    updateDatasetMetadata(updatedDataset);
  };

  const renderErrorMessage = () => {
    if (!error) return null;

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className="error-container"
        role="alert"
        aria-live="polite">
        <div className="error-message">
          {error.detail || "An error occurred while fetching the data"}
        </div>
      </Grid>
    );
  };

  if (error) {
    return renderErrorMessage();
  }

  return list.length > 0 ? (
    <div
      className="table-global-dataset-list-container"
      onScroll={(event) => {
          if (next) {
            const urlSearchParams = new URLSearchParams(
              "?" + next.split("?")[1]
            );
            const params = Object.fromEntries(urlSearchParams.entries());

            setPage(params.page);
          }
        }}
    >
      {renderErrorMessage()}
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: 16,
          marginBottom: 16,
          rowGap: 16,
          columnGap: 21,
        }}
        >
        {stableSort(list, getComparator(order, orderBy)).map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;
          return <MetaDataCard key={row.id} labelId={labelId} onPencilClick={() => handlePencilClick(row)} {...row} />;
        })}
      </Box>
      {isDialogOpen && (
        <EditDialog
          isOpen={isDialogOpen}
          onClose={handleDialogClose}
          dataset={selectedDataset}
          onUpdate={handleUpdate}
        />
      )}
    </div>
  ) : (
    <Grid container justify="center" alignItems="center">
      {error ? renderErrorMessage() : <CircularProgress />}
    </Grid>
  );
};

export default GlobalDatasetList;
