export const LOGIN_USER = 'LOGIN_USER';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const SET_DATASET = 'SET_DATASET';
export const SET_MEDICAL_DATASET = 'SET_MEDICAL_DATASET';
export const SET_AUTONOMOUS_DATASET = 'SET_AUTONOMOUS_DATASET';
export const SET_DATASET_IMAGES = 'SET_DATASET_IMAGES';
export const SAVE_DATASET_IN_DB = 'SAVE_DATASET_IN_DB';
export const CREATING_DATASET = 'CREATING_DATASET'; // create data
export const CREATE_DATASET = 'CREATE_DATASET';
export const UPDATE_DATASET = 'UPDATE_DATASET';
export const LOADING_DATASETS = 'LOADING_DATASETS';
export const LOAD_DATASETS = 'LOAD_DATASETS';
export const ARCHIVE_DATASET = 'ARCHIVE_DATASET';
export const UNARCHIVE_DATASET = 'UNARCHIVE_DATASET';
export const LOADING_EXPERIMENTS = 'LOADING_EXPERIMENTS';
export const LOADING_ARCHIVED_EXPERIMENTS = 'LOADING_ARCHIVED_EXPERIMENTS';
export const LOAD_EXPERIMENTS = 'LOAD_EXPERIMENTS';
export const LOAD_ARCHIVED_EXPERIMENTS = 'LOAD_ARCHIVED_EXPERIMENTS';
export const UPDATE_EXPERIMENTS_STATUS = 'UPDATE_EXPERIMENTS_STATUS';
export const LOADING_COMPETITIONS = 'LOADING_COMPETITIONS';
export const LOAD_COMPETITIONS = 'LOAD_COMPETITIONS';
export const JOIN_COMPETITION = 'JOIN_COMPETITION';

export const SET_ERROR = 'SET_ERROR';