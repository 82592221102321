import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const UpdateDeviceDialog = ({
  open,
  onClose,
  onUpdate,
  device,
  isLoading,
  apiErrors,
}) => {
  const [updatedDevice, setUpdatedDevice] = useState({
    first_name: "",
    location: "",
    pue_constant: 1.0,
    tdp_of_cpu: 1.0,
    v_cpu_cores: 1,
    tdp_of_gpu: 1.0,
    v_gpu_cores: 1.0,
  });

  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (device) {
      setUpdatedDevice({
        first_name: device.first_name || "",
        location: device.location || "",
        pue_constant: device.pue_constant || 1.0,
        tdp_of_cpu: device.tdp_of_cpu || 1.0,
        v_cpu_cores: device.v_cpu_cores || 1,
        tdp_of_gpu: device.tdp_of_gpu || 1.0,
        v_gpu_cores: device.v_gpu_cores || 1.0,
      });
    }
  }, [device]);

  const validateForm = () => {
    const newErrors = {};

    if (!updatedDevice.first_name) newErrors.first_name = "Name is required";
    if (!updatedDevice.location) newErrors.location = "Location is required";
    if (updatedDevice.pue_constant <= 0)
      newErrors.pue_constant = "Must be greater than 0";
    if (updatedDevice.tdp_of_cpu <= 0)
      newErrors.tdp_of_cpu = "Must be greater than 0";
    if (updatedDevice.v_cpu_cores <= 0)
      newErrors.v_cpu_cores = "Must be greater than 0";
    if (updatedDevice.tdp_of_gpu <= 0)
      newErrors.tdp_of_gpu = "Must be greater than 0";
    if (updatedDevice.v_gpu_cores <= 0)
      newErrors.v_gpu_cores = "Must be greater than 0";

    setValidationErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getFieldError = (fieldName) => {
    return apiErrors?.[fieldName]?.[0] || validationErrors[fieldName] || "";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedDevice((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (validationErrors[name]) {
      setValidationErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleUpdate = () => {
    if (validateForm()) {
      onUpdate(updatedDevice);
    }
  };

  const formatApiErrors = (errors) => {
    if (!errors) return null;
    return Object.entries(errors)
      .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
      .join("\n");
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box p={2}>
        <DialogTitle>Update Client</DialogTitle>
        <DialogContent>
          {apiErrors && (
            <Box mb={2}>
              <Alert severity="error" style={{ whiteSpace: "pre-line" }}>
                {formatApiErrors(apiErrors)}
              </Alert>
            </Box>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginTop: "16px",
            }}>
            <TextField
              name="first_name"
              label="Name"
              fullWidth
              value={updatedDevice.first_name}
              onChange={handleInputChange}
              variant="outlined"
              error={!!getFieldError("first_name")}
              helperText={getFieldError("first_name")}
            />
            <TextField
              name="location"
              label="Location"
              fullWidth
              value={updatedDevice.location}
              onChange={handleInputChange}
              variant="outlined"
              error={!!getFieldError("location")}
              helperText={getFieldError("location")}
            />
            <TextField
              name="pue_constant"
              label="PUE Constant"
              type="number"
              fullWidth
              value={updatedDevice.pue_constant}
              onChange={handleInputChange}
              variant="outlined"
              error={!!getFieldError("pue_constant")}
              helperText={getFieldError("pue_constant")}
            />
            <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
              <TextField
                name="tdp_of_cpu"
                label="TDP of CPU"
                type="number"
                fullWidth
                value={updatedDevice.tdp_of_cpu}
                onChange={handleInputChange}
                variant="outlined"
                error={!!getFieldError("tdp_of_cpu")}
                helperText={getFieldError("tdp_of_cpu")}
              />
              <TextField
                name="v_cpu_cores"
                label="CPU Cores"
                type="number"
                fullWidth
                value={updatedDevice.v_cpu_cores}
                onChange={handleInputChange}
                variant="outlined"
                error={!!getFieldError("v_cpu_cores")}
                helperText={getFieldError("v_cpu_cores")}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
              <TextField
                name="tdp_of_gpu"
                label="TDP of GPU"
                type="number"
                fullWidth
                value={updatedDevice.tdp_of_gpu}
                onChange={handleInputChange}
                variant="outlined"
                error={!!getFieldError("tdp_of_gpu")}
                helperText={getFieldError("tdp_of_gpu")}
              />
              <TextField
                name="v_gpu_cores"
                label="GPU Cores"
                type="number"
                fullWidth
                value={updatedDevice.v_gpu_cores}
                onChange={handleInputChange}
                variant="outlined"
                error={!!getFieldError("v_gpu_cores")}
                helperText={getFieldError("v_gpu_cores")}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Box style={{ padding: "16px" }}>
            <Button onClick={onClose} color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button
              onClick={handleUpdate}
              color="primary"
              variant="contained"
              disabled={isLoading}>
              {isLoading ? "Updating..." : "Update Client"}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UpdateDeviceDialog;
