import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Grid,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const AddDomainDialog = ({ open, onClose, onAdd, isLoading, apiErrors }) => {
  const [domainName, setDomainName] = useState("");
  const [topLevelDomain, setTopLevelDomain] = useState("");
  const [isPrivate, setIsPrivate] = useState(true);
  const [validationError, setValidationError] = useState("");

  const validateDomain = () => {
    const domainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]$/;
    const tldRegex = /^[a-zA-Z]{2,}$/;

    if (!domainName) {
      return "Domain name is required";
    }
    if (!topLevelDomain) {
      return "Top level domain is required";
    }
    if (!domainRegex.test(domainName)) {
      return "Invalid domain name format";
    }
    if (!tldRegex.test(topLevelDomain)) {
      return "Invalid top level domain format";
    }
    return "";
  };

  const handleDomainNameChange = (e) => {
    setDomainName(e.target.value.toLowerCase());
    setValidationError("");
  };

  const handleTopLevelDomainChange = (e) => {
    setTopLevelDomain(e.target.value.toLowerCase());
    setValidationError("");
  };

  const handleAdd = () => {
    const error = validateDomain();
    if (error) {
      setValidationError(error);
      return;
    }

    onAdd({
      domain_name: domainName,
      top_level_domain: topLevelDomain,
      is_private: isPrivate,
    });
  };

  const handleClose = () => {
    setDomainName("");
    setTopLevelDomain("");
    setIsPrivate(true);
    setValidationError("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Box p={2}>
        <DialogTitle>Add Domain</DialogTitle>
        <DialogContent>
          {apiErrors && (
            <Box mb={2}>
              <Alert severity="error">
                {typeof apiErrors === 'string' ? apiErrors : 'Failed to add domain'}
              </Alert>
            </Box>
          )}
          
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextField
                  label="Domain Name"
                  fullWidth
                  value={domainName}
                  onChange={handleDomainNameChange}
                  variant="outlined"
                  error={!!validationError}
                  placeholder="example"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Top Level Domain"
                  fullWidth
                  value={topLevelDomain}
                  onChange={handleTopLevelDomainChange}
                  variant="outlined"
                  error={!!validationError}
                  placeholder="com"
                />
              </Grid>
            </Grid>
            
            {validationError && (
              <Typography color="error" variant="caption" style={{ marginTop: '8px' }}>
                {validationError}
              </Typography>
            )}

            <Box mt={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isPrivate}
                    onChange={(e) => setIsPrivate(e.target.checked)}
                    color="primary"
                  />
                }
                label="Private Domain"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box style={{ padding: "16px" }}>
            <Button onClick={handleClose} color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button
              onClick={handleAdd}
              color="primary"
              variant="contained"
              disabled={isLoading || !!validationError}
            >
              {isLoading ? "Adding..." : "Add Domain"}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddDomainDialog; 