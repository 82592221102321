import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const ConfirmDeleteDomainDialog = ({ 
  open, 
  onClose, 
  onConfirm, 
  isLoading, 
  apiErrors,
  domain 
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Box p={2}>
        <DialogTitle>Delete Domain</DialogTitle>
        <DialogContent>
          {apiErrors && (
            <Box mb={2}>
              <Alert severity="error">
                {typeof apiErrors === 'string' ? apiErrors : 'Failed to delete domain'}
              </Alert>
            </Box>
          )}
          
          <Box mt={2}>
            <Typography>
              Are you sure you want to delete the domain "{domain?.domain_name}.{domain?.top_level_domain}"?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box style={{ padding: "16px" }}>
            <Button onClick={onClose} color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button
              onClick={onConfirm}
              color="primary"
              style={{ backgroundColor: "rgba(227, 16, 16, 0.8)" }}
              variant="contained"
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Delete Domain"}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmDeleteDomainDialog; 