import React, { useContext, useEffect, useState } from "react";
import { Avatar, Grid, Popover } from "@material-ui/core";
import PhonelinkSetupOutlinedIcon from '@material-ui/icons/PhonelinkSetupOutlined';

import "./style.css";
import logo from "assets/logo-icon.svg";
import logoFull from "assets/logo-full.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { ToggleButton } from "@material-ui/lab";
import { Link, useLocation } from "react-router-dom";
import { GlobalContext } from "Context/GlobalState";

import CompeteImg from "assets/icons/Compete@4x.png";
import DocumentationImg from "assets/icons/docs.svg";
import DatasetsImg from 'assets/icons/DataSets@4x.png'
import DiscordImg from "assets/icons/discord-icon-svgrepo-com.svg";
import UserPopup from "../UserPopup";

const pagesList = [
  {
    title: "Collaborations",
    target: "_self",
    path: "/collaborations",
    icon: <img alt="Collaborations" src={CompeteImg} />,
  },
  {
    title: "Documentation",
    target: "_blank",
    path: "//docs.tracebloc.io",
    icon: <img alt="Documentation" src={DocumentationImg} />,
  },
  {
    title: "Data",
    target: "_self",
    path: "/data",
    icon: <img alt="Data" src={DatasetsImg} style={{ height: '16px' }} />,
  },
  {
    title: "Clients",
    target: "_self",
    path: "/clients",
    icon: <PhonelinkSetupOutlinedIcon style={{ marginRight: 20 }} />,
  },
];

const Sidebar = (props) => {
  const { sidebar, openSideBar, user, getUserInfo } = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const { open } = sidebar;
  let { pathname: path } = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("_key_usr_tkn");
    if (!user && token) {
      getUserInfo();
    }
  }, [user, getUserInfo]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openUser = Boolean(anchorEl);
  const id = openUser ? "simple-popover" : undefined;

  return (
    <Grid className="sidebar-links" container>
      <a className="logo" href="/">
        <img
          style={{ height: open ? "36px" : "47px" }}
          src={open ? logoFull : logo}
          alt="tracebloc"
        />
      </a>
      <div className="toggle-button">
        <div className="line"></div>
        <ToggleButton
          className={`${open ? "toggle-close" : "toggle-open"}`}
          selected={open}
          onChange={() => openSideBar(!open)}
          value="closed">
          «
        </ToggleButton>
      </div>

      <div className="menu-items">
        {pagesList.map((page) => {
          return (
            <Link
              key={page.path}
              to={page.path}
              target={page.target}
              className={`button${
                page.path === path ? " button-selected" : ""
              }`}>
              <Tooltip title={`${page.title}`}>
                <div className="img-container">{page.icon}</div>
              </Tooltip>

              <span className="label">{page.title}</span>
            </Link>
          );
        })}
      </div>

      <Grid
        item
        style={{
          marginTop: "auto",
          marginBottom: "60px",
          flexBasis: "auto",
          padding: "16px 16px 0px",
        }}>
        <a
          style={{
            position: "fixed",
            margin: "auto",
            marginBottom: "2px",
            marginLeft: "6px",
            bottom: "60px",
          }}
          key={"discord"}
          href={"https://discord.gg/suXKUCAMfA"}
          target="_blank"
          rel="noreferrer">
          <Tooltip title={"Discord"}>
            <div className="img-container">
              <img
                style={{ width: "20px", filter: "grayscale(0.8)" }}
                src={DiscordImg}
                alt="Discord"
              />
            </div>
          </Tooltip>
        </a>
        {user && (
          <>
            <Tooltip title={`To ${user.first_name} ${user.last_name} Profile`}>
              <Avatar
                onClick={handleClick}
                src={user.profile_image}
                className={"avatar"}
                alt={`${user.first_name} ${user.last_name}`}
              />
            </Tooltip>

            <Popover
              id={id}
              open={openUser}
              anchorEl={anchorEl}
              onClose={handleClose}
              elevation={0}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "left",
              }}>
              <UserPopup
                profile_image={user.profile_image}
                itsMe={true}
                user={user}
                closeCallback={handleClose}
              />
            </Popover>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Sidebar;
