import { Box, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { companyService } from "services/api/company";
import CompanyDetails from "./CompanyDetails";
import "./style.css";

const Company = () => {
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);

  const getCompanyDetails = async () => {
    try {
      const response = await companyService.getCompanyDetails();
      setCompany(response.data.results[0]);
    } catch (error) {
      console.error("Error fetching company details:", error);
      setCompany(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);

  return (
    <Box paddingX={7}>
      <Box pb={0} className="title-italic-24">
        Company
      </Box>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <Box pb={0} mt={1} ml={5} className="label-margin">
            <span className="bold-700-12">
              View and manage your company details
            </span>
          </Box>
        </Grid>
      </Grid>

      <Paper variant="outlined" style={{ marginBottom: "8px" }}>
        <Box className="tab-Panel" style={{ padding: '16px' }}>
          <CompanyDetails 
            company={company} 
            loading={loading}
            onUpdate={getCompanyDetails}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default Company; 