import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { locationChoices } from "../../utils";
import { Autocomplete } from "@material-ui/lab";

const AddDeviceDialog = ({ open, onClose, onAdd, isLoading, apiErrors }) => {
  const [newDevice, setNewDevice] = useState({
    name: "",
    password: "",
    confirmPassword: "",
    location: "",
    pue_constant: 1.0,
    tdp_of_cpu: 1.0,
    v_cpu_cores: 1,
    tdp_of_gpu: 1.0,
    v_gpu_cores: 1.0,
  });

  const [validationErrors, setValidationErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!newDevice.name) newErrors.name = "Name is required";
    if (!newDevice.password) newErrors.password = "Password is required";
    if (!newDevice.confirmPassword)
      newErrors.confirmPassword = "Please confirm password";
    if (newDevice.password !== newDevice.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (!newDevice.location) newErrors.location = "Location is required";
    if (newDevice.pue_constant <= 0)
      newErrors.pue_constant = "Must be greater than 0";
    if (newDevice.tdp_of_cpu <= 0)
      newErrors.tdp_of_cpu = "Must be greater than 0";
    if (newDevice.v_cpu_cores <= 0)
      newErrors.v_cpu_cores = "Must be greater than 0";
    if (newDevice.tdp_of_gpu <= 0)
      newErrors.tdp_of_gpu = "Must be greater than 0";
    if (newDevice.v_gpu_cores <= 0)
      newErrors.v_gpu_cores = "Must be greater than 0";

    setValidationErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getFieldError = (fieldName) => {
    return apiErrors?.[fieldName]?.[0] || validationErrors[fieldName] || "";
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDevice((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear validation error when user starts typing
    if (validationErrors[name]) {
      setValidationErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleClose = () => {
    setNewDevice({
      name: "",
      password: "",
      confirmPassword: "",
      location: "",
      pue_constant: 1.0,
      tdp_of_cpu: 1.0,
      v_cpu_cores: 1,
      tdp_of_gpu: 1.0,
      v_gpu_cores: 1.0,
    });
    setValidationErrors({});
    onClose();
  };

  const handleAdd = () => {
    if (validateForm()) {
      const deviceData = { ...newDevice };
      delete deviceData.confirmPassword;
      onAdd(deviceData);
    }
  };

  // Function to format API errors into a readable string
  const formatApiErrors = (errors) => {
    if (!errors) return null;

    return Object.entries(errors)
      .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
      .join("\n");
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Box p={2}>
        <DialogTitle>Add New Client</DialogTitle>
        <DialogContent>
          {apiErrors && (
            <Box mb={2}>
              <Alert severity="error" style={{ whiteSpace: "pre-line" }}>
                {formatApiErrors(apiErrors)}
              </Alert>
            </Box>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              marginTop: "16px",
            }}>
            <TextField
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={newDevice.name}
              onChange={handleInputChange}
              variant="outlined"
              error={!!getFieldError("name")}
              helperText={getFieldError("name")}
            />
            <TextField
              name="password"
              label="Password"
              type="password"
              fullWidth
              value={newDevice.password}
              onChange={handleInputChange}
              variant="outlined"
              error={!!getFieldError("password")}
              helperText={getFieldError("password")}
            />
            <TextField
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              fullWidth
              value={newDevice.confirmPassword}
              onChange={handleInputChange}
              variant="outlined"
              error={!!getFieldError("confirmPassword")}
              helperText={getFieldError("confirmPassword")}
            />
            <FormControl 
              fullWidth 
              error={!!getFieldError("location")}
            >
              <Autocomplete
                id="location-select"
                options={locationChoices}
                getOptionLabel={(option) => option.label}
                value={locationChoices.find(loc => loc.value === newDevice.location) || null}
                onChange={(_, newValue) => {
                  handleInputChange({
                    target: {
                      name: 'location',
                      value: newValue?.value || ''
                    }
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location"
                    variant="outlined"
                    error={!!getFieldError("location")}
                    helperText={getFieldError("location")}
                  />
                )}
              />
            </FormControl>
            <TextField
              name="pue_constant"
              label="PUE Constant"
              type="number"
              fullWidth
              value={newDevice.pue_constant}
              onChange={handleInputChange}
              variant="outlined"
              error={!!getFieldError("pue_constant")}
              helperText={getFieldError("pue_constant")}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              marginTop: "16px",
            }}>
            <TextField
              name="tdp_of_cpu"
              label="TDP of CPU"
              type="number"
              fullWidth
              value={newDevice.tdp_of_cpu}
              onChange={handleInputChange}
              variant="outlined"
              error={!!getFieldError("tdp_of_cpu")}
              helperText={getFieldError("tdp_of_cpu")}
            />
            <TextField
              name="v_cpu_cores"
              label="CPU Cores"
              type="number"
              fullWidth
              value={newDevice.v_cpu_cores}
              onChange={handleInputChange}
              variant="outlined"
              error={!!getFieldError("v_cpu_cores")}
              helperText={getFieldError("v_cpu_cores")}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              marginTop: "16px",
            }}>
            <TextField
              name="tdp_of_gpu"
              label="TDP of GPU"
              type="number"
              fullWidth
              value={newDevice.tdp_of_gpu}
              onChange={handleInputChange}
              variant="outlined"
              error={!!getFieldError("tdp_of_gpu")}
              helperText={getFieldError("tdp_of_gpu")}
            />
            <TextField
              name="v_gpu_cores"
              label="GPU Cores"
              type="number"
              fullWidth
              value={newDevice.v_gpu_cores}
              onChange={handleInputChange}
              variant="outlined"
              error={!!getFieldError("v_gpu_cores")}
              helperText={getFieldError("v_gpu_cores")}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Box style={{ padding: "16px" }}>
            <Button onClick={handleClose} color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button
              onClick={handleAdd}
              color="primary"
              variant="contained"
              disabled={isLoading}>
              {isLoading ? "Adding..." : "Add Client"}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddDeviceDialog;
