import {
  Grid,
  Collapse,
  TableCell,
  TableRow,
  Tooltip as MuiToolTip,
  CircularProgress,
  Snackbar,
  Portal,
  Checkbox,
} from "@material-ui/core";
import React, { useState } from "react";

import {
  convertToMilligramAndGram,
  formatDate,
  formatNumberToKMBT,
  numberWithSpaces,
  printNumberSign,
} from "utils/format";
import ExperimentRow from "Components/ExperimentsList/ExperimentRow";
import { Alert } from "@material-ui/lab";
import { NotificationCheckIcon } from "Components/Icon";
import { collaborationsService } from "services/api/collaborations";
import { experimentsService } from "services/api/experiments";

function MySubmissionsRow(props) {
  const {
    row,
    labelId,
    onClick,
    position,
    totalEntries,
    dataset,
    is_private,
    baseEnergyConsumption,
    totalFinalSubmissions,
    submitSubmission,
    finalSubmission,
    addSubmission,
    deleteSubmission,
    ...rowProps
  } = props;
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [experiment, setExperiment] = useState(null);
  const [loadingSubmission, setLoadingSubmission] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [flash, setFlash] = useState(false);

  const rowOpen = () => {
    setOpen(!open);
    getExperimentDetails();
    onClick();
  };

  const getExperimentDetails = async () => {
    setLoading(true);
    try {
      const response = await experimentsService.getExperimentDetails(
        dataset,
        row.experiment_id
      );
      const data = response.data;
      setExperiment({ ...data[0] });
      setLoading(false);
      setError(null);
    } catch (error) {
      if (error.response) {
        setError("Please make sure your credentials are correct.");
      } else {
        setError("Make sure you are connected to the internet.");
      }
      setLoading(false);
    }
  };

  const submitThisSubmission = async (e) => {
    e.stopPropagation();

    if (e.target.checked && totalFinalSubmissions > 1) {
      setFlash(true);
      setTimeout(() => setFlash(false), 1000);
      return;
    }

    const data = {
      experiment: row.experiment_id,
      cycle: row.cycle,
    };

    setLoadingSubmission(true);

    try {
      if (e.target.checked) {
        const response = await collaborationsService.submitFinalSubmission(
          dataset,
          data
        );
        addSubmission(response.data);
        setSuccessMessage("Inference Submitted successfully.");
      } else {
        await collaborationsService.deleteFinalSubmission(
          dataset,
          finalSubmission[0]?.id
        );
        deleteSubmission(finalSubmission[0]?.id);
        setSuccessMessage("Inference Deleted Successfully.");
      }

      setSuccess(true);
      setError(null);
      setLoadingSubmission(false);
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data);
      } else {
        setError("Make sure you are connected to the internet.");
      }
      setLoadingSubmission(false);
    }
  };

  const errorMessages = () => {
    // Check if the 'experiment' key exists in the error data
    let errorList = null;
    if (error.experiment) {
      errorList = error.experiment.map((message, index) => (
        <p style={{ textAlign: "left" }} key={index}>
          experiment: {message}
        </p>
      ));
    }

    // Check if the 'cycle' key exists in the error data
    if (error.cycle) {
      const cycleList = error.cycle.map((message, index) => (
        <p style={{ textAlign: "left" }} key={index}>
          cycle: {message}
        </p>
      ));
      errorList = [errorList, ...cycleList];
    }

    if (error.non_field_errors) {
      const cycleList = error.non_field_errors.map((message, index) => (
        <p style={{ textAlign: "left" }} key={index}>
          {message}
        </p>
      ));
      errorList = [errorList, ...cycleList];
    }

    // If none of the expected keys are found, display a generic error message
    return errorList ? (
      errorList
    ) : (
      <p style={{ textAlign: "left" }}>An unknown error occurred.</p>
    );
  };

  return (
    <React.Fragment>
      <TableRow
        style={{
          cursor: typeof row.experiment === "object" ? "default" : "pointer",
        }}
        {...rowProps}
        onClick={typeof row.experiment !== "object" ? rowOpen : null}>
        {/* id */}
        <TableCell id={labelId} style={{ width: "45px" }}>
          {position}
        </TableCell>

        {/* Experiment Key */}
        <TableCell
          style={{
            textAlign: "left",
            width: "80px",
          }}
          id={labelId}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            {row.experiment_key}
          </div>
        </TableCell>

        {/* Cycle Number */}
        <TableCell
          style={{
            width: "50px",
          }}>
          {row.cycle}
        </TableCell>

        {/* Date */}
        <TableCell>{formatDate(new Date(row.created_date))}</TableCell>

        {/* accuracy */}
        <TableCell>
          <MuiToolTip title={`Accuracy`}>
            <div>
              {(row.inference_captured &&
                parseFloat(row.accuracy || 0).toFixed(4)) ||
                "~"}
            </div>
          </MuiToolTip>
        </TableCell>

        {/* Loss */}
        <TableCell>
          <MuiToolTip title={`Loss`}>
            <div>
              {(row.inference_captured &&
                parseFloat(row.loss || 0).toFixed(4)) ||
                "~"}
            </div>
          </MuiToolTip>
        </TableCell>

        {/* model_parameters */}
        <TableCell>
          <MuiToolTip
            title={`Score calculated using ${row?.score_formula_display}`}>
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
                width: "100%",
              }}>
              <div style={{ marginRight: 8, width: "50%", textAlign: "right" }}>
                {(row?.model_parameters &&
                  formatNumberToKMBT(row?.model_parameters || 0)) ||
                  ""}
              </div>

              <div style={{ marginLeft: 8, width: "50%", textAlign: "left" }}>
                {(row?.model_size &&
                  formatNumberToKMBT(row?.model_size || 0) + "B") ||
                  ""}
              </div>
            </div>
          </MuiToolTip>
        </TableCell>

        {/* inference_flopsutilised */}
        <TableCell>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}>
            <div style={{ marginRight: 8, width: "50%", textAlign: "right" }}>
              {(row?.flopsutilised &&
                Number.parseInt(row?.flopsutilised || 0)
                  .toExponential(2)
                  .replace(/e\+/g, " e")
                  .replace(/e-/g, " e-")) ||
                ""}
            </div>

            <div style={{ marginLeft: 8, width: "50%", textAlign: "left" }}>
              {position === 1
                ? "-"
                : (row?.flopsutilised &&
                    numberWithSpaces(
                      printNumberSign(
                        (
                          ((row?.flopsutilised - baseEnergyConsumption) /
                            baseEnergyConsumption) *
                          100
                        )
                          .toFixed(1)
                          .replace(/\.0$/, "")
                      ) + " %"
                    )) ||
                  ""}
            </div>
          </div>
        </TableCell>

        {/*gco2 */}
        {
          <TableCell>
            <MuiToolTip title={`co2e`}>
              <div>
                {(row.inference_captured &&
                  numberWithSpaces(
                    convertToMilligramAndGram(parseFloat(row.gco2))
                  )) ||
                  "~"}
              </div>
            </MuiToolTip>
          </TableCell>
        }

        {/* Submit */}
        <TableCell className="my-submission-cycle-btn">
          {loadingSubmission ? (
            <div className="custom-checkbox-unchecked">
              <CircularProgress size={18} />
            </div>
          ) : (
            <Checkbox
              onClick={submitThisSubmission}
              color="default"
              checked={finalSubmission.length > 0}
              checkedIcon={
                <span style={{ border: "1px solid #3576a7", padding: "1px" }}>
                  <span className="custom-checked-icon"></span>
                </span>
              }
              icon={
                <span style={{ border: "1px solid #3576a7", padding: "1px" }}>
                  <span className="custom-unchecked-icon" />
                </span>
              }
              classes={{
                root: `custom-checkbox-unchecked ${
                  flash ? "flash-while-disabled" : ""
                }`,
                checked: "custom-checkbox-checked",
              }}
            />
          )}
        </TableCell>
      </TableRow>

      {/* 
                ////// Expansion ////////////////
                /////\\\\\\\\\\\\\\///////////////
      */}
      <TableRow>
        <TableCell
          style={{ border: 0, paddingBottom: 0, paddingTop: 0 }}
          colSpan={12}>
          <Collapse
            className="experiment-container"
            in={typeof row.experiment !== "object" && open} // PLEASE UNCOMMENT WHEN PERMISSIONS IMPLEMENTED
            timeout="auto"
            unmountOnExit>
            {experiment && (
              <ExperimentRow
                key={experiment.id}
                expIndex={0}
                experimentData={experiment}
                showHeader={false}
              />
            )}
            {!experiment && loading && (
              <Grid container justify="center" alignItems="center">
                <CircularProgress />
              </Grid>
            )}
          </Collapse>
        </TableCell>
      </TableRow>

      <Portal>
        {success && (
          <Snackbar
            open={success}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            onClose={() => setSuccess(null)}>
            <Alert
              iconMapping={{
                success: <NotificationCheckIcon style={{ fontSize: 20 }} />,
              }}
              variant="filled"
              severity="success">
              {successMessage}
            </Alert>
          </Snackbar>
        )}

        {error && (
          <Snackbar
            open={error}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            onClose={() => setError(null)}>
            <Alert icon={false} variant="filled" severity="error">
              {errorMessages()}
            </Alert>
          </Snackbar>
        )}

        {
          <Snackbar
            open={flash}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            onClose={() => setError(null)}>
            <Alert icon={false} variant="filled" severity="error">
              Max 2 submissions allowed. Please uncheck a submission.
            </Alert>
          </Snackbar>
        }
      </Portal>
    </React.Fragment>
  );
}

export default MySubmissionsRow;
