import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const AddAdminDialog = ({ open, onClose, onAdd, isLoading, apiErrors }) => {
  const [emails, setEmails] = useState("");
  const [validationError, setValidationError] = useState("");

  const validateEmails = (emailString) => {
    const emailList = emailString.split(",").map(email => email.trim());
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    for (const email of emailList) {
      if (!emailRegex.test(email)) {
        return `Invalid email format: ${email}`;
      }
    }
    return "";
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmails(value);
    setValidationError(validateEmails(value));
  };

  const handleAdd = () => {
    const error = validateEmails(emails);
    if (error) {
      setValidationError(error);
      return;
    }

    const emailList = emails.split(",").map(email => email.trim());
    onAdd(emailList);
  };

  const handleClose = () => {
    setEmails("");
    setValidationError("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <Box p={2}>
        <DialogTitle>Add Members</DialogTitle>
        <DialogContent>
          {apiErrors && (
            <Box mb={2}>
              <Alert severity="error">
                {typeof apiErrors === 'string' ? apiErrors : 'Failed to add members'}
              </Alert>
            </Box>
          )}
          
          <Box mt={2}>
            <TextField
              label="Email Addresses"
              fullWidth
              multiline
              rows={4}
              value={emails}
              onChange={handleInputChange}
              variant="outlined"
              error={!!validationError}
              helperText={validationError || "Enter email addresses separated by commas"}
            />
            <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
              Example: admin1@example.com, admin2@example.com
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box style={{ padding: "16px" }}>
            <Button onClick={handleClose} color="primary" disabled={isLoading}>
              Cancel
            </Button>
            <Button
              onClick={handleAdd}
              color="primary"
              variant="contained"
              disabled={isLoading || !!validationError}>
              {isLoading ? "Adding..." : "Add Members"}
            </Button>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AddAdminDialog; 