import axios from "axios";
import { API_URL, getAuthConfig } from "./config";

export const collaborationsService = {
  getCollaborations: async (page) => {
    return axios.get(
      `${API_URL}/competition/${page ? "?page=" + page : ""}`,
      getAuthConfig()
    );
  },

  joinCollaboration: async (dataset) => {
    return axios.patch(
      `${API_URL}/competition/${dataset}/join_competition/`,
      null,
      getAuthConfig()
    );
  },

  getNotifications: async (cancelToken) => {
    return axios.get(`${API_URL}/notifications/`, {
      ...getAuthConfig(),
      cancelToken,
    });
  },

  getSingleCollaboration: async (competitionId) => {
    return axios.get(
      `${API_URL}/competition/${competitionId}/`,
      getAuthConfig()
    );
  },

  updateNotification: async (notificationId, data) => {
    return axios.patch(
      `${API_URL}/notifications/${notificationId}/update/`,
      data,
      getAuthConfig()
    );
  },

  performNotificationAction: async (notificationId, action) => {
    console.log(notificationId, action);
    return axios.post(
      `${API_URL}/notifications/${notificationId}/${action}`,
      null,
      getAuthConfig()
    );
  },

  getLeaderboardSummary: async (datasetId) => {
    return axios.get(
      `${API_URL}/leaderboard/${datasetId}/summary/`,
      getAuthConfig()
    );
  },

  getNotebookJson: async (jsonUrl) => {
    return axios.get(jsonUrl, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },

  getMySubmissions: async (dataset, page) => {
    return axios.get(
      `${API_URL}/inference/${dataset}/${page ? "?page=" + page : ""}`,
      getAuthConfig()
    );
  },
  getFinalSubmissions: async (dataset) => {
    return axios.get(`${API_URL}/submit/final/${dataset}/`, getAuthConfig());
  },

  submitFinalSubmission: async (dataset, data, submissionId = "") => {
    return axios.post(
      `${API_URL}/submit/final/${dataset}/${submissionId}`,
      data,
      getAuthConfig()
    );
  },

  deleteFinalSubmission: async (dataset, submissionId) => {
    return axios.delete(
      `${API_URL}/submit/final/${dataset}/${submissionId}/`,
      getAuthConfig()
    );
  },

  getLeaderboardEntries: async (dataset, page) => {
    return axios.get(
      `${API_URL}/leaderboard/${dataset}/${page ? "?page=" + page : ""}`,
      getAuthConfig()
    );
  },

  updateCollaboration: async (collabId, data) => {
    const formData = new FormData();
    
    // Handle different types of data
    Object.entries(data).forEach(([key, value]) => {
      if (value === null || value === undefined) return;

      switch (key) {
        case 'flops':
          formData.append('flops', value * 1e12);
          break;
        case 'dates':
          Object.entries(value).forEach(([dateKey, dateValue]) => {
            if (dateValue instanceof Date) {
              formData.append(dateKey, dateValue.toISOString());
            }
          });
          break;
        case 'eda_pdf':
          if (value instanceof File) {
            formData.append('eda_pdf', value);
          }
          break;
        default:
          if (value instanceof Date) {
            formData.append(key, value.toISOString());
          } else if (typeof value === 'number') {
            formData.append(key, value.toString());
          } else {
            formData.append(key, value);
          }
      }
    });

    return axios.patch(
      `${API_URL}/competition/${collabId}/`,
      formData,
      getAuthConfig()
    );
  },

  toggleExperimentControl: async (collabId, allowExperiments) => {
    return axios.post(
      `${API_URL}/competition/${collabId}/admin_experiment_control/`,
      { allow_experiments: allowExperiments },
      getAuthConfig()
    );
  },

  inviteUsers: async (collabId, emails) => {
    return axios.patch(
      `${API_URL}/competition/${collabId}/admin_invite/`,
      { emails },
      getAuthConfig()
    );
  },

  deleteUser: async (collabId, email) => {
    return axios.patch(
      `${API_URL}/competition/${collabId}/admin_uninvite/`,
      { emails: email },
      getAuthConfig()
    );
  },

  createCollaboration: async (data) => {
    const formData = new FormData();
    
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'eda_pdf' && value instanceof File) {
        formData.append('eda_pdf', value);
      } else if (value instanceof Date) {
        formData.append(key, value.toISOString());
      } else if (typeof value === 'number') {
        formData.append(key, value.toString());
      } else if (value !== null && value !== undefined) {
        formData.append(key, value);
      }
    });

    return axios.post(
      `${API_URL}/competition/`,
      formData,
      {
        ...getAuthConfig(),
        headers: {
          ...getAuthConfig().headers,
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },

  getScoreFormulaChoices: async () => {
    return axios.get(
      `${API_URL}/general/choice-group/competition_score_formulas/`,
      getAuthConfig()
    );
  },

  publishCollaboration: async (collabId) => {
    return axios.post(
      `${API_URL}/competition/${collabId}/publish/`,
      null,
      getAuthConfig()
    );
  },
};

export const inviteToDataset = async (email, datasetId) => {
  const config = {
    method: "POST",
    url: `${API_URL}/invitations/invite/`,
    data: { email, datasets: [datasetId] },
    ...getAuthConfig(),
  };

  return axios(config);
};

export const inviteToTeam = async (data, teamId) => {
  const isEmail = data.includes("@");
  const key = isEmail ? "email" : "merger_team";

  return await axios.post(
    `${API_URL}/invitations/team-invite/`,
    { [key]: data, team: teamId },
    getAuthConfig()
  );
};

export const updateTeamName = async (teamId, newName) => {
  return await axios.patch(
    `${API_URL}/team/${teamId}/`,
    { name: newName },
    getAuthConfig()
  );
};
