import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Switch,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { datasetService } from 'services/api/dataset';
import './style.css';

const EditDialog = ({ isOpen, onClose, dataset, onUpdate }) => {
  const [title, setTitle] = useState(dataset.title);
  const [description, setDescription] = useState(dataset.description || '');
  const [requires_gpu, setRequiresGpu] = useState(dataset.requires_gpu || false);

  const handleSubmit = async () => {
    try {
      const updatedDataset = await datasetService.updateDatasetDetails(dataset.id, {
        title,
        description,
          requires_gpu,
      });
      onUpdate(updatedDataset.data);
    } catch (error) {
      console.error('Failed to update dataset', error);
    }
    onClose();
  };

  return (
    <Dialog className="edit-metadata-dialog" open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <Box className="edit-metadata-dialog-container">
        <IconButton aria-label="close" className="close-button" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        
        <Box className="title-container">
          <DialogTitle className="dialog-title">Edit Metadata Card</DialogTitle>
          <Typography className="dialog-subtitle">
            View and edit the details for you specific card details.
          </Typography>
        </Box>
        
        <Box className="form-fields-container">
          <Box className="field-container">
            <Typography className="field-label">Title</Typography>
            <TextField
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              variant="outlined"
              placeholder="Meta-data"
              className="text-field"
              InputProps={{
                'aria-label': 'Title'
              }}
            />
          </Box>
          
          <Box className="field-container">
            <Typography className="field-label">Description</Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
              placeholder="Enter Description"
              className="text-field"
              InputProps={{
                'aria-label': 'Description'
              }}
            />
          </Box>
          
          <Box className="switch-container">
            <Switch
              checked={requires_gpu}
              onChange={(e) => setRequiresGpu(e.target.checked)}
              color="primary"
              className="feature-switch"
              inputProps={{
                'aria-label': 'Requires GPU toggle'
              }}
            />
            <Typography className="switch-label">
              Requires GPU
            </Typography>
          </Box>
        </Box>
        
        <DialogActions>
          <Button 
            onClick={handleSubmit} 
            className="update-button" 
            variant="contained"
            endIcon={<ArrowRightAltIcon />}
          >
            Update
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditDialog; 