import { useContext, useEffect } from "react";

import { Box, Button, Grid, IconButton, Paper } from "@material-ui/core";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import { GlobalContext } from "./Context/GlobalState";
import Collaborations from "./Pages/Collaborations";
import CollaborationDetailsPage from "./Pages/CollaborationDetails";
import Login from "./Pages/Login";
import UpdatePassword from "./Pages/UpdatePassword";
import AcceptInvite from "./Pages/AcceptInvite";
import UpdateProfile from "./Pages/UpdateProfile";
import ResetPassword from "./Pages/ResetPassword";
import ForgotPassword from "./Pages/ForgotPassword";
import Register from "Pages/Register";
import ActivateAccount from "Pages/ActivateAccount";
import OnBoard from "Pages/OnBoard";

import Joyride from "react-joyride";
import { useMount } from "react-use";
import { useTourContext } from "Context/TourContext";
import { BackIcon, CloseGreyIcon } from "Components/Icon";
import EdgeDevices from "./Pages/EdgeDevices";
import GlobalDatasets from "Pages/GlobalDatasets";
import Company from "Pages/Company";

const Routes = (props) => {
  const { sidebar, user } = useContext(GlobalContext);

  return user ? (
    <Router>
      <Box className="traceblocData">
        {user.is_password_changed && (
          <Box
            className="sidebar"
            style={{ maxWidth: sidebar.open ? "184px" : "65px" }}>
            <Sidebar />
          </Box>
        )}
        <Box
          className="main"
          style={{
            marginLeft: sidebar.open ? "184px" : "65px",
            backgroundColor: user.is_password_changed
              ? "#FAFAFA"
              : "transparent",
            paddingTop: user.is_password_changed ? "44px" : "0px",
          }}>
          <Switch>
            <ProtectedRoute exact path="/" render={() => <Relogin />} />
            <ProtectedRoute
              exact
              path="/collaborations"
              children={<Collaborations />}
            />

            <ProtectedRoute
              exact
              path="/data"
              children={<GlobalDatasets />}
            />
            <ProtectedRoute
              exact
              path="/clients"
              children={<EdgeDevices />}
            />

            <ProtectedRoute
              exact
              path="/company"
              children={<Company />}
            />
            <ProtectedRoute
              path="/collaborations/:competitionId?/details/:tab?"
              children={<CollaborationDetailsPage />}
            />
            <ProtectedRoute
              path="/update-password"
              children={<UpdatePassword />}
            />
            <ProtectedRoute path="/onboard" children={<OnBoard />} />
            <ProtectedRoute
              path="/update-profile"
              children={<UpdateProfile />}
            />
          </Switch>
        </Box>
      </Box>
    </Router>
  ) : (
    <Router>
      <Box className="traceblocData">
        <Box
          className="sidebar"
          style={{ maxWidth: sidebar.open ? "184px" : "65px" }}>
          <Sidebar />
        </Box>

        <Box
          className="main"
          style={{
            marginLeft: sidebar.open ? "184px" : "65px",
            backgroundColor: "#FAFAFA",
            paddingTop: "44px",
          }}>
          <Switch>
            <Route
              path="/accept-invite/:inviteKey"
              children={<AcceptInvite />}
            />
            <Route
              path="/activate/:userKey/:activationKey"
              children={<ActivateAccount />}
            />
            <Route
              path="/reset-password/:resetKey"
              children={<ResetPassword />}
            />
            <Route path="/forgot-password/" children={<ForgotPassword />} />
            <Route path="/signup" children={<Register />} />
            <Route exact path="/" children={<Login />} />
            <Route exact path="/collaborations" children={<Collaborations />} />
            <Route
              path="/collaborations/:competitionId?/details/:tab?"
              children={<CollaborationDetailsPage />}
            />
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Box>
      </Box>
    </Router>
  );
};

function NoMatch() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    history.push({
      pathname: "/",
      state: {
        redirect_to: location.pathname,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

function Relogin() {
  const location = useLocation();

  if (location?.state?.redirect_to) {
    return <Redirect to={location?.state?.redirect_to} />;
  }

  return <Redirect to="/collaborations" />;
}

const ProtectedRoute = (props) => {
  const { user } = useContext(GlobalContext);
  const {
    setState,
    state: { run, stepIndex, steps },
  } = useTourContext();

  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const location = useLocation();

  useEffect(() => {
    if (!user) {
      window.location = "/";
    }
  }, [user]);

  useEffect(() => {
    const datasetKey = localStorage.getItem("datasetKey");

    if (user && !user.is_password_changed) {
      history.push({
        pathname: `/onboard/`,
        state: {
          first_time: true,
          email: location?.state?.email,
        },
      });
    } else if (user && (!user.first_name || !user.last_name)) {
      history.push({
        pathname: `/update-profile/`,
        state: {
          first_time: true,
        },
      });
    } else if (user && datasetKey) {
      localStorage.removeItem("datasetKey");
      history.push({
        pathname: `/experiments/${datasetKey || ""}`,
      });
    }
  }, [history, location?.state?.email, pathname, user]);

  useMount(() => {
    setState({
      steps: [
        {
          target: "#first-dataset-row",
          content: (
            <div className="tour">
              <h3>Here are the datasets​</h3>
              <p></p>
              <div>
                You will see all datasets, trainings and interactions with your
                peers here.
              </div>
            </div>
          ),
          disableBeacon: true,
        },
        {
          target: "#first-dataset-icon",
          content: (
            <div className="tour">
              <h3>Check out the EDA for the dataset</h3>
              <p></p>
              <div>
                The automated Exploratory Data Analysis provides you with
                everything you want to know about the dataset.
              </div>
            </div>
          ),
        },
        {
          target: "#first-experiment-row",
          content: (
            <div className="tour">
              <h3>Each training has its own scorecard</h3>
              <p></p>
              <div>
                Monitor your training, download the trained model and weights,
                interact with your peers.
              </div>
            </div>
          ),
        },
      ],
    });
  });

  const handleCallback = (data: CallBackProps) => {
    const { action, index, lifecycle, type } = data;

    if (action === "reset" || action === "close" || type === "tour:end") {
      localStorage.setItem("tour", "done");
      setState({ run: false, stepIndex: 0, tourActive: false });
      return;
    }

    if (type === "step:after" && index === 0 /* or step.target === '#home' */) {
      // setState({ run: false });
      setState({ run: true, stepIndex: 1 });
    } else if (type === "step:after" && index === 1) {
      // setState({ run: false });
      if (action === "next") {
        setState({ run: true, stepIndex: 2 });
      } else {
        setState({ run: true, stepIndex: 0 });
      }
    } else if (type === "step:after" && index === 2) {
      if (action === "next") {
        setState({ run: true, stepIndex: 3 });
      } else {
        setState({ run: true, stepIndex: 1 });
      }
    } else if (type === "step:after" && index === 3) {
      if (action === "next") {
        setState({ run: true, stepIndex: 4 });
      } else {
        setState({ run: true, stepIndex: 2 });
      }
    } else if (
      action === "reset" ||
      action === "close" ||
      lifecycle === "complete"
    ) {
      localStorage.setItem("tour", "done");
      setState({ run: false, stepIndex: 0, tourActive: false });
    }
  };

  return (
    <>
      <Route {...props} children={props.children} />

      <Joyride
        callback={handleCallback}
        continuous
        run={run}
        spotlightClicks
        spotlightPadding={12}
        showProgress
        debug
        scrollOffset={80}
        stepIndex={stepIndex}
        steps={steps}
        tooltipComponent={Tooltip}
        styles={{
          options: {
            zIndex: 1000,
          },
          spotlight: {
            borderRadius: 2,
            border: "2px solid #29B5C4",
            position: "absolute",
          },
        }}
      />
    </>
  );
};

function Tooltip({
  backProps,
  continuous,
  index,
  isLastStep,
  primaryProps,
  closeProps,
  skipProps,
  step,
  size,
  tooltipProps,
}: TooltipRenderProps) {
  return (
    <Paper
      {...tooltipProps}
      style={{
        width: "260px",
        overflow: "hidden",
        backgroundColor: "white",
        padding: "24px 2px",
        borderRadius: "2px",
        boxShadow: "0px 3px 6px #00000029",
      }}>
      <Box
        style={{
          paddingBottom: "12px",
          paddingLeft: "20px",
          paddingRight: "20px",
        }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <div
            style={{
              fontSize: "12px",
              lineHeight: "16px",
              fontStyle: "italic",
              fontWeight: "normal",
              color: "#C7D2D5",
            }}>
            {index + 1}/{size}
          </div>
          <IconButton {...closeProps}>
            <CloseGreyIcon style={{ fontSize: "14px" }} />
          </IconButton>
        </Grid>
      </Box>

      <Box style={{ padding: "0px 22px" }}>
        {step.title && (
          <h3 mb="md" variant="primary">
            {step.title}
          </h3>
        )}
        {step.content && <Box>{step.content}</Box>}
      </Box>

      <Box
        style={{
          paddingTop: "18px",
          paddingLeft: "8px",
          paddingRight: "20px",
        }}>
        <Grid container justifyContent="space-between" alignItems="center">
          {index > 0 && (
            <IconButton {...backProps}>
              <BackIcon style={{ fontSize: "16px" }} />
            </IconButton>
          )}
          <Button
            {...primaryProps}
            style={{
              marginLeft: "auto",
              border: "1px solid #4BBDCC",
              borderRadius: "30px",
              textAlign: "center",
              fontSize: "12px",
              lineHeight: "16px",
              fontWeight: "normal",
              color: "#4BBDCC",
              textTransform: "uppercase",
              minWidth: "48px",
              height: "28px",
            }}>
            {continuous && !isLastStep ? "next" : "end"}
          </Button>
        </Grid>
      </Box>
    </Paper>
  );
}

export default Routes;
