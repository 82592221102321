import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.css";
import { withStyles } from "@material-ui/styles";
import { CloseSharp } from "@material-ui/icons";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import CompanyOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import ViewListOutlinedIcon from "@material-ui/icons/ViewListOutlined";
import Divider from "@material-ui/core/Divider";
import { GlobalContext } from "Context/GlobalState";
import { AddPictureIcon, HandIcon, RunIcon } from "../Icon";
import { authService } from "services/api/auth";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative",
  },
  fabProgress: {
    position: "absolute",
    top: "0px",
    left: "0px",
    zIndex: 1,
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#bdbdbd",
    color: "#fff",
    width: "15px",
    minWidth: "15px",
    height: "15px",
    borderRadius: "50%",
    border: "1px solid #FFFFFF",
    "& .MuiSvgIcon-root": {
      fontSize: "15px",
    },
  },
}))(Badge);

const UserPopup = ({ user, profile_image, closeCallback, itsMe }) => {
  const { logout, setUserUpdate } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [summary, setSummary] = useState(null);
  const profilePicRef = useRef(null);
  const classes = useStyles();
  const history = useHistory();

  const getUserSummary = () => {
    setLoading(true);
    const userId = user?.id || user?.user_id;

    authService
      .getUserSummary(userId)
      .then((res) => {
        setSummary({ ...res.data });
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        if (error.response) {
          setError("Please make sure you are logged in.");
        } else {
          setError("Make sure you are connected to the internet.");
        }
        setLoading(false);
      });
  };
  useEffect(() => {
    if (user) {
      getUserSummary();
    }
  }, []);

  const closePopup = (e) => {
    e.stopPropagation();
    closeCallback();
  };

  const handleLogout = () => {
    logout();
  };

  const handleCompany = () => {
    history.push("/company");
  };

  const updateProfile = ({ profile_image }) => {
    setUploading(true);

    const formData = new FormData();
    if (profile_image) {
      formData.append("profile_image", profile_image);
    }

    authService
      .updateProfileImage(formData)
      .then((res) => {
        setError(null);
        setUploading(false);
        setUserUpdate({ ...res.data });
      })
      .catch((error) => {
        let errorMessage = "Make sure you are connected to the internet.";
        if (error.response?.status === 404) {
          errorMessage = error.response.statusText;
        } else if (error.response) {
          errorMessage = "An Error occurred.";
        }

        setError("error", {
          type: "manual",
          message: errorMessage,
        });
        setUploading(false);
      });
  };

  return (
    <div className="user-popup">
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="user-header">
        <Grid item>
          {summary ? (
            <span className="trainings">
              {summary.total_running_experiments}
            </span>
          ) : (
            <CircularProgress color="inherit" size={10} />
          )}{" "}
          <span>Trainings running</span>
        </Grid>
        <Grid item>
          <Button onClick={closePopup} className="close-button">
            <CloseSharp className="icon" />{" "}
          </Button>
        </Grid>
      </Grid>

      <Box mt={1} className="user-ds-info">
        <Grid container className="item">
          <Grid item className="icon-container">
            <ViewListOutlinedIcon className="icon" />
          </Grid>
          <Grid item className="datasets">
            {summary ? (
              <span>{summary.total_datasets}</span>
            ) : (
              <CircularProgress size={10} />
            )}
          </Grid>
          <Grid item className="text">
            <span>Datasets created</span>
          </Grid>
        </Grid>
        <Grid container className="item">
          <Grid item className="icon-container">
            <HandIcon className="icon" />
          </Grid>
          <Grid item className="datasets">
            {summary ? (
              <span>{summary.total_claps_received}</span>
            ) : (
              <CircularProgress size={10} />
            )}
          </Grid>
          <Grid item className="text">
            <span>Claps received</span>
          </Grid>
        </Grid>
        <Grid container className="item">
          <Grid item className="icon-container">
            <RunIcon className="icon" />
          </Grid>
          <Grid item className="datasets">
            {summary ? (
              <span>{summary.total_experiments}</span>
            ) : (
              <CircularProgress size={10} />
            )}
          </Grid>
          <Grid item className="text">
            <span>Experiments run</span>
          </Grid>
        </Grid>
      </Box>

      <Divider />
      {itsMe && (
        <List
          className="user-actions"
          component="nav"
          aria-label="user settings">
          <ListItem button className="item" onClick={handleCompany}>
            <ListItemIcon className="icon-container">
              <CompanyOutlinedIcon className="icon" />
            </ListItemIcon>
            <ListItemText primary="Company" className="text" />
          </ListItem>
          <ListItem button className="item" onClick={handleLogout}>
            <ListItemIcon className="icon-container">
              <ExitToAppOutlinedIcon className="icon" />
            </ListItemIcon>
            <ListItemText primary="Logout" className="text" />
          </ListItem>
        </List>
      )}

      <Grid container className="user-container" alignItems="center">
        <Grid item>
          {itsMe && (
            <input
              ref={profilePicRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="profile-picture-file"
              onChange={(e) => {
                console.log("FILE", e.target.files[0]);
                const file = e.target.files[0];
                if (file && file["type"].split("/")[0] === "image") {
                  updateProfile({ profile_image: e.target.files[0] });
                }
                setPreview(e.target.files[0]);
              }}
            />
          )}
          <label htmlFor="profile-picture-file">
            <IconButton
              component="span"
              style={{ padding: "6px", marginRight: "4px" }}>
              <StyledBadge
                component="span"
                className={classes.wrapper}
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={itsMe ? <AddPictureIcon /> : null}>
                <Avatar
                  variant="circular"
                  className={"avatar"}
                  alt={`${user?.first_name}`}
                  src={
                    preview
                      ? URL.createObjectURL(preview)
                      : profile_image || "/images/avatar.png"
                  }
                />
                {uploading ? (
                  <CircularProgress className={classes.fabProgress} size={32} />
                ) : null}
              </StyledBadge>
            </IconButton>
          </label>
        </Grid>

        <Grid item>
          <div className="user-name">
            {user?.first_name} {user?.last_name}
          </div>
          <div className="user-email">{user?.email}</div>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserPopup;
