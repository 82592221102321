import React, { useState, useRef, useContext } from "react";
import {
  Grid,
  CircularProgress,
  Box,
  Avatar,
  Typography,
  Chip,
  Divider,
  Button,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import AddAdminDialog from "./AddAdminDialog";
import AddDomainDialog from "./AddDomainDialog";
import { companyService } from "services/api/company";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmRemoveDialog from "./ConfirmRemoveDialog";
import InlineEdit from "./InlineEdit";
import EditIcon from "@material-ui/icons/Edit";
import ConfirmDeleteDomainDialog from "./ConfirmDeleteDomainDialog";
import TransferOwnershipDialog from "./TransferOwnershipDialog";
import { GlobalContext } from "Context/GlobalState";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  logo: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    marginBottom: theme.spacing(2),
    '& img': {
      objectFit: 'contain',
    },
  },
  adminAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  domainChip: {
    margin: theme.spacing(0.5),
  },
  adminCard: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: '#f5f5f5',
    borderRadius: theme.spacing(1),
  },
  adminInfo: {
    marginLeft: theme.spacing(1),
  },
  logoWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  editButton: {
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
  },
  logoInput: {
    display: 'none',
  },
}));

const CompanyDetails = ({ company, loading, onUpdate }) => {
  const classes = useStyles();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [addingAdmins, setAddingAdmins] = useState(false);
  const [apiErrors, setApiErrors] = useState(null);
  const [openDomainDialog, setOpenDomainDialog] = useState(false);
  const [addingDomain, setAddingDomain] = useState(false);
  const [domainApiErrors, setDomainApiErrors] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [removingMember, setRemovingMember] = useState(false);
  const [removeApiErrors, setRemoveApiErrors] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [openDeleteDomainDialog, setOpenDeleteDomainDialog] = useState(false);
  const [deletingDomain, setDeletingDomain] = useState(false);
  const [deleteDomainApiErrors, setDeleteDomainApiErrors] = useState(null);
  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const [transferring, setTransferring] = useState(false);
  const [transferApiErrors, setTransferApiErrors] = useState(null);
  const { user } = useContext(GlobalContext);
  const isOwner = user?.user_id === company?.owner?.id;

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
    setApiErrors(null);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setApiErrors(null);
  };

  const handleAddAdmins = async (emails) => {
    setAddingAdmins(true);
    setApiErrors(null);

    try {
      await companyService.addAdmins(emails, company.id);
      handleCloseAddDialog();
      onUpdate(); // Refresh company details
    } catch (error) {
      console.error("Error adding administrators:", error);
      setApiErrors(error.response?.data?.message || "Failed to add administrators");
    } finally {
      setAddingAdmins(false);
    }
  };

  const handleOpenDomainDialog = () => {
    setOpenDomainDialog(true);
    setDomainApiErrors(null);
  };

  const handleCloseDomainDialog = () => {
    setOpenDomainDialog(false);
    setDomainApiErrors(null);
  };

  const handleAddDomain = async (domainData) => {
    setAddingDomain(true);
    setDomainApiErrors(null);

    try {
      await companyService.addDomain(domainData, company.id);
      handleCloseDomainDialog();
      onUpdate(); // Refresh company details
    } catch (error) {
      console.error("Error adding domain:", error);
      setDomainApiErrors(error.response?.data?.message || "Failed to add domain");
    } finally {
      setAddingDomain(false);
    }
  };

  const handleOpenRemoveDialog = (member) => {
    setSelectedMember(member);
    setOpenRemoveDialog(true);
    setRemoveApiErrors(null);
  };

  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
    setRemoveApiErrors(null);
    setSelectedMember(null);
  };

  const handleRemoveMember = async () => {
    if (!selectedMember) return;

    setRemovingMember(true);
    setRemoveApiErrors(null);

    try {
      await companyService.removeAdmins([selectedMember.email], company.id);
      handleCloseRemoveDialog();
      onUpdate(); // Refresh company details
    } catch (error) {
      console.error("Error removing member:", error);
      setRemoveApiErrors(error.response?.data?.error || "Failed to remove member");
    } finally {
      setRemovingMember(false);
    }
  };

  const handleLogoClick = () => {
    fileInputRef.current?.click();
  };

  const handleLogoChange = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('company_logo', file);

    try {
      await companyService.updateCompanyDetails(formData, company.id);
      onUpdate();
    } catch (error) {
      console.error("Error updating logo:", error);
    }
  };

  const handleNameChange = async (newName) => {
    try {
      await companyService.updateCompanyDetails({ name: newName }, company.id);
      onUpdate();
    } catch (error) {
      console.error("Error updating name:", error);
    }
  };

  const handleTypeChange = async (newType) => {
    try {
      await companyService.updateCompanyDetails({ company_type: newType }, company.id);
      onUpdate();
    } catch (error) {
      console.error("Error updating type:", error);
    }
  };

  const handleOpenDeleteDomainDialog = (domain) => {
    setSelectedDomain(domain);
    setOpenDeleteDomainDialog(true);
    setDeleteDomainApiErrors(null);
  };

  const handleCloseDeleteDomainDialog = () => {
    setOpenDeleteDomainDialog(false);
    setDeleteDomainApiErrors(null);
    setSelectedDomain(null);
  };

  const handleDeleteDomain = async () => {
    if (!selectedDomain) return;

    setDeletingDomain(true);
    setDeleteDomainApiErrors(null);

    try {
      await companyService.deleteDomain(selectedDomain.id);
      handleCloseDeleteDomainDialog();
      onUpdate(); // Refresh company details
    } catch (error) {
      console.error("Error deleting domain:", error);
      setDeleteDomainApiErrors(error.response?.data?.message || "Failed to delete domain");
    } finally {
      setDeletingDomain(false);
    }
  };

  const handleOpenTransferDialog = () => {
    setOpenTransferDialog(true);
    setTransferApiErrors(null);
  };

  const handleCloseTransferDialog = () => {
    setOpenTransferDialog(false);
    setTransferApiErrors(null);
  };

  const handleTransferOwnership = async (email) => {
    setTransferring(true);
    setTransferApiErrors(null);

    try {
      await companyService.transferOwnership(email, company.id);
      handleCloseTransferDialog();
      onUpdate(); // Refresh company details
    } catch (error) {
      console.error("Error transferring ownership:", error);
      setTransferApiErrors(error.response?.data?.message || "Failed to transfer ownership");
    } finally {
      setTransferring(false);
    }
  };

  if (loading) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ minHeight: "200px" }}>
        <CircularProgress />
      </Grid>
    );
  }

  if (!company) {
    return (
      <Box p={3}>
        <Typography variant="h6" color="error">
          No company information available
        </Typography>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <div className={classes.logoWrapper}>
              <Avatar
                src={company.company_logo}
                alt={company.name}
                className={classes.logo}
              />
              <IconButton
                className={classes.editButton}
                size="small"
                onClick={handleLogoClick}
              >
                <EditIcon fontSize="small" />
              </IconButton>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                className={classes.logoInput}
                onChange={handleLogoChange}
              />
            </div>
            <Box mt={2} width="100%" textAlign="center">
              <InlineEdit
                value={company.name}
                onSave={handleNameChange}
                Typography={Typography}
                typographyProps={{
                  variant: "h5",
                  gutterBottom: true,
                }}
              />
              <InlineEdit
                value={company.company_type}
                onSave={handleTypeChange}
                Typography={Typography}
                typographyProps={{
                  variant: "subtitle1",
                  color: "textSecondary",
                }}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <div className={classes.section}>
            <Typography variant="h6" gutterBottom>
              Company Admin
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <div className={classes.adminCard}>
                  <Avatar
                    src={company.owner.profile_image}
                    alt={`${company.owner.first_name} ${company.owner.last_name}`}
                    className={classes.adminAvatar}
                  />
                  <div className={classes.adminInfo}>
                    <Typography variant="subtitle1">
                      {`${company.owner.first_name} ${company.owner.last_name}`}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Owner
                    </Typography>
                  </div>

                  {isOwner && (
                    <Box
                      p={0}
                      marginLeft="auto"
                      bgcolor="#f5f5f5"
                      height="100%"
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: "rgba(227, 16, 16, 0.8)" }}
                        onClick={handleOpenTransferDialog}
                        fullWidth
                      >
                        Transfer Ownership
                      </Button>
                    </Box>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>

          <Divider />

          <div className={classes.section}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
                Members
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleOpenAddDialog}
              >
                Add Member
              </Button>
            </Box>
            <Grid container spacing={2}>
              {company.admins.map((admin) => (
                <Grid item xs={12} sm={6} key={admin.id}>
                  <div className={classes.adminCard}>
                    <Box display="flex" alignItems="center" width="100%">
                      <Avatar
                        src={admin.profile_image}
                        alt={`${admin.first_name} ${admin.last_name}`}
                        className={classes.adminAvatar}
                      />
                      <div className={classes.adminInfo}>
                        <Typography variant="subtitle1">
                          {`${admin.first_name} ${admin.last_name}`}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                         {admin.id === company.owner.id ? "Owner" : "Member"}
                        </Typography>
                      </div>
                      {admin.id !== company.owner.id && (
                        <IconButton
                          onClick={() => handleOpenRemoveDialog(admin)}
                          style={{ marginLeft: 'auto' }}
                          size="small"
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      )}
                    </Box>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>

          <Divider />

          <div className={classes.section}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6" gutterBottom style={{ marginTop: '16px' }}>
                Domains
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleOpenDomainDialog}
              >
                Add Domain
              </Button>
            </Box>
            <Box display="flex" flexWrap="wrap">
              {company.domains.map((domain) => (
                <Box key={domain.id} display="flex" alignItems="center" m={0.5}>
                  <Chip
                    label={`${domain.domain_name}.${domain.top_level_domain}`}
                    className={classes.domainChip}
                    color={domain.is_private ? "primary" : "default"}
                    onDelete={() => handleOpenDeleteDomainDialog(domain)}
                  />
                </Box>
              ))}
            </Box>
          </div>
        </Grid>
      </Grid>

      <AddAdminDialog
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        onAdd={handleAddAdmins}
        isLoading={addingAdmins}
        apiErrors={apiErrors}
        companyId={company.id}
      />

      <AddDomainDialog
        open={openDomainDialog}
        onClose={handleCloseDomainDialog}
        onAdd={handleAddDomain}
        isLoading={addingDomain}
        apiErrors={domainApiErrors}
      />

      <ConfirmRemoveDialog
        open={openRemoveDialog}
        onClose={handleCloseRemoveDialog}
        onConfirm={handleRemoveMember}
        isLoading={removingMember}
        apiErrors={removeApiErrors}
        member={selectedMember}
      />

      <ConfirmDeleteDomainDialog
        open={openDeleteDomainDialog}
        onClose={handleCloseDeleteDomainDialog}
        onConfirm={handleDeleteDomain}
        isLoading={deletingDomain}
        apiErrors={deleteDomainApiErrors}
        domain={selectedDomain}
      />

      <TransferOwnershipDialog
        open={openTransferDialog}
        onClose={handleCloseTransferDialog}
        onTransfer={handleTransferOwnership}
        isLoading={transferring}
        apiErrors={transferApiErrors}
        currentOwner={company.owner}
      />
    </div>
  );
};

export default CompanyDetails; 