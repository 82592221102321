import React from "react";
import { Box, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const AddCollaborationCard = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      className="cursor-pointer hover:bg-gray-50 transition-colors"
      sx={{
        width: 302,
        // height: 440,
        border: "2px dashed #3576A7",
        borderRadius: 6,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
      }}>
      <AddIcon
        style={{
          fontSize: 48,
          color: "#3576A7",
          marginBottom: 16,
        }}
      />
      <Typography
        style={{
          color: "#3576A7",
          fontSize: 18,
          fontWeight: "bold",
          fontStyle: "italic",
        }}>
        Create New Collaboration
      </Typography>
      <Typography
        style={{
          color: "#707070",
          fontSize: 12,
          fontStyle: "italic",
          textAlign: "center",
          maxWidth: 200,
          marginTop: 8,
        }}>
        Start a new AI collaboration project with your team
      </Typography>
    </Box>
  );
};

export default AddCollaborationCard; 