import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { collaborationsService } from "services/api/collaborations";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { GlobalContext } from "Context/GlobalState";
import { checkCompetitors } from "utils";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "#3576A7",
  },
  title: {
    textAlign: "center",
  },
  contactUs: {
    border: "2px solid #4576A3",
    borderRadius: "30px",
    width: "245px",
    height: "59px",
    marginBottom: "8px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    padding: theme.spacing(2),
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
    alignItems: "center",
    width: '100%',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#4576A3',
        },
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#3576A7',
          borderWidth: '2px',
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#E0E0E0',
    },
    '& .MuiInputLabel-outlined': {
      color: '#666666',
      '&.Mui-focused': {
        color: '#3576A7',
      },
    },
    '& .MuiInputBase-input': {
      padding: '14px',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '8px',
      '& .MuiInputBase-input': {
        padding: '8px',
      },
    },
  },
  select: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#4576A3',
        },
      },
    },
    '& .MuiInputLabel-outlined': {
      color: '#666666',
      '&.Mui-focused': {
        color: '#3576A7',
      },
    },
  },
  error: {
    color: theme.palette.error.main,
    fontSize: '0.75rem',
    marginTop: theme.spacing(-1.5),
    marginLeft: theme.spacing(1.75),
  },
  buttonProgress: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: 'relative',
  },
  snackbarMessage: {
    textAlign: 'left !important',
  },
}));

export default function CreateCollaborationDialog({ open, onClose }) {
  const classes = useStyles();
  const { state, updateCollaboration } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  
  // Get current date and add 30 days for preparation
  const preparationDate = new Date();
  const announcedDate = new Date(preparationDate);
  announcedDate.setDate(announcedDate.getDate() + 30);
  
  // Set other dates with proper intervals
  const startDate = new Date(announcedDate);
  startDate.setDate(startDate.getDate() + 15);
  
  const teamMergerDate = new Date(startDate);
  teamMergerDate.setDate(teamMergerDate.getDate() + 1);
  
  const finalSubmissionDate = new Date(teamMergerDate);
  finalSubmissionDate.setDate(finalSubmissionDate.getDate() + 1);
  
  const endDate = new Date(finalSubmissionDate);
  endDate.setDate(endDate.getDate() + 1);

  const [formData, setFormData] = useState({
    title: "",
    goal: "",
    description: "",
    privacy_type: "company",
    announced_date: announcedDate.toISOString().slice(0, 16),
    start_date: startDate.toISOString().slice(0, 16),
    team_merger_date: teamMergerDate.toISOString().slice(0, 16),
    final_submission_date: finalSubmissionDate.toISOString().slice(0, 16),
    end_date: endDate.toISOString().slice(0, 16),
  });

  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });

  const validateForm = () => {
    const newErrors = {};

    // Required field validations
    if (!formData.title.trim()) newErrors.title = 'Title is required';
    if (!formData.goal.trim()) newErrors.goal = 'Goal is required';
    if (!formData.description.trim()) newErrors.description = 'Description is required';

    // Date validations
    const dateValidationErrors = validateDates();
    Object.assign(newErrors, dateValidationErrors);

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateDates = () => {
    const newErrors = {};
    const dates = {
      announced: new Date(formData.announced_date),
      start: new Date(formData.start_date),
      teamMerger: new Date(formData.team_merger_date),
      finalSubmission: new Date(formData.final_submission_date),
      end: new Date(formData.end_date),
    };

    if (dates.announced <= preparationDate) {
      newErrors.announced_date = 'Announced date must be after preparation date';
    }
    if (dates.start <= dates.announced) {
      newErrors.start_date = 'Start date must be after announced date';
    }
    if (dates.teamMerger <= dates.start) {
      newErrors.team_merger_date = 'Team merger date must be after start date';
    }
    if (dates.finalSubmission <= dates.teamMerger) {
      newErrors.final_submission_date = 'Final submission date must be after team merger date';
    }
    if (dates.end <= dates.finalSubmission) {
      newErrors.end_date = 'End date must be after final submission date';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (field) => (event) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });
    // Clear validation error when user starts typing
    if (errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const handleEditorChange = (field) => (event, editor) => {
    const data = editor.getData();
    setFormData({
      ...formData,
      [field]: data,
    });
    // Clear validation error when user starts typing
    if (errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      setSnackbar({
        open: true,
        message: 'Please fix the validation errors',
        severity: 'error',
      });
      return;
    }

    setLoading(true);
    try {
      const response = await collaborationsService.createCollaboration({
        ...formData,
      });
      
      // Process the response data to match the competitions list format
      const newCollaboration = response.data;
      const winning_prizes = newCollaboration.prizes;
      const prize = winning_prizes && winning_prizes.reduce((previousValue, currentValue) => {
        return parseFloat(previousValue) + parseFloat(currentValue.prize_amount);
      }, 0.0);

      const processedCollaboration = {
        ...newCollaboration,
        isNewCollaboration: true,
        loading: false,
        prize: prize?.toFixed(2),
        remaining: newCollaboration.end_date,
        competitors: checkCompetitors(newCollaboration.competitor_companies)
          ? newCollaboration.competitor_companies[0].company_type
          : "Mixed",
      };

      // Update the global state with the new collaboration
      updateCollaboration(processedCollaboration);

      setSnackbar({
        open: true,
        message: 'Collaboration created successfully',
        severity: 'success',
      });
      onClose();
    } catch (error) {
      console.error("Error creating collaboration:", error);
      
      // Format API validation errors
      if (error.response?.data && typeof error.response.data === 'object') {
        const errorMessages = Object.entries(error.response.data)
          .map(([field, messages]) => {
            // Convert field name to title case and join multiple messages
            const fieldName = field
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
            return `${fieldName}: ${messages.join(', ')}`;
          })
          .join('\n');

        setSnackbar({
          open: true,
          message: errorMessages,
          severity: 'error',
        });
      } else {
        setSnackbar({
          open: true,
          message: error.response?.data?.message || 'Error creating collaboration',
          severity: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={onClose}
        PaperProps={{
          style: { borderRadius: 12 },
        }}>
        <DialogTitle>
          Create New Collaboration
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <TextField
              fullWidth
              label="Title"
              value={formData.title}
              onChange={handleChange("title")}
              className={classes.textField}
              variant="outlined"
              error={!!errors.title}
              helperText={errors.title}
            />

            <TextField
              fullWidth
              label="Goal"
              multiline
              rows={3}
              value={formData.goal}
              onChange={handleChange("goal")}
              className={classes.textField}
              variant="outlined"
              error={!!errors.goal}
              helperText={errors.goal}
            />

            <Typography variant="subtitle1">Description</Typography>
            <CKEditor
              editor={ClassicEditor}
              data={formData.description}
              onChange={handleEditorChange("description")}
            />
            {errors.description && (
              <Typography className={classes.error}>
                {errors.description}
              </Typography>
            )}
            
            <FormControl fullWidth className={classes.select} variant="outlined">
              <InputLabel>Privacy Type</InputLabel>
              <Select
                value={formData.privacy_type}
                onChange={handleChange("privacy_type")}
                label="Privacy Type">
                <MenuItem value="company">Company</MenuItem>
                <MenuItem value="public">Public</MenuItem>
              </Select>
            </FormControl>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="datetime-local"
                  label="Announced Date"
                  value={formData.announced_date}
                  onChange={handleChange("announced_date")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textField}
                  variant="outlined"
                  error={!!errors.announced_date}
                  helperText={errors.announced_date}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="datetime-local"
                  label="Start Date"
                  value={formData.start_date}
                  onChange={handleChange("start_date")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textField}
                  variant="outlined"
                  error={!!errors.start_date}
                  helperText={errors.start_date}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="datetime-local"
                  label="Team Merger Date"
                  value={formData.team_merger_date}
                  onChange={handleChange("team_merger_date")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textField}
                  variant="outlined"
                  error={!!errors.team_merger_date}
                  helperText={errors.team_merger_date}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="datetime-local"
                  label="Final Submission Date"
                  value={formData.final_submission_date}
                  onChange={handleChange("final_submission_date")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.textField}
                  variant="outlined"
                  error={!!errors.final_submission_date}
                  helperText={errors.final_submission_date}
                />
              </Grid>
            </Grid>

            <TextField
              fullWidth
              type="datetime-local"
              label="End Date"
              value={formData.end_date}
              onChange={handleChange("end_date")}
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
              variant="outlined"
              error={!!errors.end_date}
              helperText={errors.end_date}
            />
          </form>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <div className={classes.buttonWrapper}>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              className={classes.contactUs}
              disabled={loading}>
              {loading ? 'Creating...' : 'Create Draft'}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={12000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          variant="filled" 
          severity={snackbar.severity}
          style={{ whiteSpace: 'pre-line'}}
          classes={{
            message: classes.snackbarMessage,
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
