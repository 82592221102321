import React from "react";
import { Box, Button } from "@material-ui/core";
import Header from "../../Components/Header";
import GlobalDatasetList from "../../Components/GlobalDatasetList";
import { styles } from "./styles";
import AddIcon from "@material-ui/icons/Add";

const GlobalDatasets = (props) => {
  const handleCreateClick = () => {
    // Add your create metadataset logic here
    console.log("Create new metadataset clicked");
  };

  return (
    <Box style={styles.globalDatasetsContainer}>
      <Header />
      <Box style={styles.header}>
        <div style={styles.titleContainer}>
          <div style={styles.title}>Your Metadatasets</div>
          <div style={styles.subtitle}>View and manage your upload metadatsets</div>
        </div>
        <Button
          variant="text"
          style={styles.createButton}
          onClick={handleCreateClick}
          aria-label="Create New Metadataset"
          endIcon={<AddIcon style={styles.plusIcon} />}
        >
          Create New Metadataset
        </Button>
      </Box>
      <GlobalDatasetList />
    </Box>
  );
};

export default GlobalDatasets;
