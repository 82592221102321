import { Box, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { edgeDevicesService } from "services/api/edgeDevices";
import EdgeDevicesTable from "./EdgeDevicesTable";
import "./style.css";

const EdgeDevices = () => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextPage, setNextPage] = useState(null);

  const getEdgeDevices = async () => {
    try {
      const response = await edgeDevicesService.getUserEdgeDevices();
      setDevices(response.data.results.sort((a, b) => new Date(b.date_joined) - new Date(a.date_joined)));
      setNextPage(response.data.next);
    } catch (error) {
      console.error("Error fetching edge devices:", error);
      setDevices([]);
    } finally {
      setLoading(false);
    }
  };

  const handleDeviceAdded = (newDevice) => {
    setDevices([newDevice, ...devices]);
  };

  useEffect(() => {
    getEdgeDevices();
  }, []);

  return (
    <Box paddingX={7}>
      <Box pb={0} className="title-italic-24">
        Clients
      </Box>
      <Grid justifyContent="space-between" container>
        <Grid item>
          <Box pb={0} mt={1} ml={5} className="label-margin">
            <span className="bold-700-12">
              Monitor and manage your client devices
            </span>
          </Box>
        </Grid>
      </Grid>

      <Paper variant="outlined" style={{ marginBottom: "8px" }}>
        <Box className="tab-Panel" style={{ padding: '16px' }}>
          <EdgeDevicesTable
            devices={devices}
            loading={loading}
            onDeviceAdded={handleDeviceAdded}
            nextPage={nextPage} />
        </Box>
      </Paper>
    </Box>
  );
};

export default EdgeDevices; 